import {
  AppBar,
  Box,
  Button,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import logo from "../Assets/PwrLogo.png";
import youtube from "../Assets/HeaderIcon/Youtube.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import routes from "../AppRoutes/routes.json";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getNewRealses } from "../Pages/ProductPages/ClassicSearchAction";
import { CommonSetupActions } from "../Slices/Commonslice";

const LayoutComponent = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const dropdownOptions = {
    Products: [
      { label: "PUMP", route:routes.SteeringPump },
      { label: "RACK & PINION", route: routes.RackPinion },
      { label: "GEAR BOX", route: routes.SteeringGearBox },
    ],
    Resources: [
      { label: "ARTICLES", route: routes.Articles },
      { label: "VIDEOS", route: routes.Videos },
      { label: "WARRANTY", route: routes.Warranty },
    ],
    "New Releases": [
      { label: "POWER STEERING PUMP", id: 1,route:routes.Ecatlog},
      { label: "POWER STEERING RACK", id: 2,route:routes.Ecatlog },
      { label: "STEERING GEAR BOX", id: 3 ,route:routes.Ecatlog},
    ],
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuItems, setMenuItems] = useState([]);

  // Handle mouse enter for parent element
  const handleMouseEnter = (event, key) => {
    setAnchorEl(event.currentTarget); // Set the anchor element
    setMenuItems(dropdownOptions[key]); // Set menu items based on hovered key
  };

  // Handle mouse leave for both parent and menu
  const handleMouseLeave = () => {
    setAnchorEl(null); // Close the menu
  };

  const handleClick = (option, route) => {
    setAnchorEl(null);
    if (
      option.label === "POWER STEERING PUMP" ||
      option.label === "POWER STEERING RACK" ||
      option.label === "STEERING GEAR BOX"
    ) {
      dispatch(getNewRealses(option.id));
      dispatch(CommonSetupActions.setRadioValue(option.id));
      navigate(route);
    } else {
      navigate(route);
    }
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "black",
        }}
      >
        <Toolbar disableGutters>
          <Box sx={{ marginLeft: 5 }}>
            <img src={logo} alt="logo" />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "50px",
                marginLeft: 4,
              }}
            >
              {["Products", "Resources", "New Releases"].map((item) => (
                <div
                  key={item}
                  onMouseEnter={(e) => handleMouseEnter(e, item)}
                  onMouseLeave={handleMouseLeave}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "#8A8A8A",
                    }}
                  >
                    {item}
                  </Typography>
                  <KeyboardArrowDownIcon style={{ fill: "#EC1B24" }} />
                </div>
              ))}
              <div
                id="about"
                onClick={() => navigate(routes.About)}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  cursor: "pointer",
                }}
              >
                <Typography
                  sx={{
                    textTransform: "none",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "#8A8A8A",
                  }}
                >
                  About Us
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  cursor: "pointer",
                }}
                onClick={props.scrollToGetInTouch}
              >
                <Typography
                  sx={{ fontWeight: 500, fontSize: "14px", color: "#8A8A8A" }}
                >
                  Contact Us
                </Typography>
              </div>
            </Box>

            <Box
              sx={{
                marginRight: 10,
                display: "flex",
                flexDirection: "row",
                gap: 5,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box>
                <a
                  href="https://www.youtube.com/@PWRSteer"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={youtube} alt="youtube" />
                </a>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  cursor: "pointer",
                }}
                onClick={props.scrollToGetInTouch}
              >
                <HelpOutlineIcon style={{ fill: "#EC1B24" }} />
                <Typography
                  sx={{ fontWeight: 500, fontSize: "14px", color: "#8A8A8A" }}
                >
                  FAQ's
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  startIcon={<PermIdentityIcon />}
                  sx={{
                    backgroundColor: "#EC1B24",
                    width: "112px",
                    height: "42px",
                    borderRadius: "100px",
                    padding: 2,
                    textTransform: "none",
                  }}
                  onClick={props.scrollToGetInTouch}
                >
                  Support
                </Button>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Dropdown Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMouseLeave}
        onMouseEnter={() => setAnchorEl(anchorEl)} // Keep menu open when hovering over it
        onMouseLeave={handleMouseLeave} // Close menu on mouse leave
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "black", // Change background to black
            padding: 2,
            boxShadow: "0px 4px 15px rgba(255, 0, 0, 0.4)",
          },
        }}
      >
        {menuItems.map((option, index) => (
          <MenuItem
            key={index}
            onClick={() => handleClick(option, option.route)}
            sx={{
              color: "white", // MenuItem text color
              "&:hover": {
                backgroundColor: "#333333", // Optional: Add hover effect for the menu item
              },
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>

      <div>{props.children}</div>
    </>
  );
};

export default LayoutComponent;
