import { combineReducers, configureStore } from "@reduxjs/toolkit";
import CommonSliceReducer, { CommonSetupActions } from "../Slices/Commonslice";

const appReducer = combineReducers({
  CommonStore: CommonSliceReducer,
});

const Store  = configureStore({
    reducer:appReducer,
  //   middleware : (getDefaultMiddleware)=>getDefaultMiddleware({
  //     serializableCheck : {
  //         ignoredActions : [
  //             CommonSetupActions.getMapvalue.type,
            
  //             CommonSetupActions.updateDrawPolygonControls.type,
             
  //         ],
  //         ignoredPaths : [
  //             'CommonStore',
             
  //         ]
  //     }
  // })
})

export default Store;