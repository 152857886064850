import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React from "react";
import pump from "../../Assets/ProductImages/HydraulicPump.png";
import rack from "../../Assets/ProductImages/SteeringRack.png";
import gear from "../../Assets/ProductImages/GearBox.png";
import reservoir from "../../Assets/ProductImages/Reservoir.png";
import GridViewIcon from "@mui/icons-material/GridView";

const OurProduct = () => {
  const cardContent = [
    { image: pump, name: "Hydraulic Pump" },
    { image: rack, name: "Steering Rack" },
    { image: gear, name: "Gear Box" },
    { image: reservoir, name: "Reservoir" },
  ];
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: 10,
      }}
    >
      <Box>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "36px",
            color: "#55120B",
            marginTop: "60px",
            fontFamily: "DM Sans, sans-serif",
          }}
        >
          Our Products
        </Typography>
      </Box>
      <Box sx={{ width: "60%" }}>
        <Typography
          sx={{
            textAlign: "justify",
            fontFamily: "DM Sans, sans-serif",
            fontWeight: 400,
            fontSize: "14px",
            color: "#333333",
          }}
        >
          PWR Steer products are crafted to meet stringent engineering
          standards, ensuring top-tier performance and reliability you can
          trust. Each component is meticulously tested to uphold high levels of
          quality and durability, delivering superior steering solutions built
          to excel in any condition. Choose PWR Steer for the confidence that
          comes with rigorously tested, dependable products designed with
          excellence at every step.
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 6, marginTop: 5 }}>
        {cardContent.map((data) => {
          return (
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "300px",
                  height: "320px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#F2F2F2",
                }}
              >
                <Box
                  sx={{
                    width: "250px",
                    height: "220px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={data.image}
                    alt={data.name}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "contain", // Ensures the image scales properly
                    }}
                  />
                </Box>
              </Box>
              <CardContent>
                <Typography sx={{ fontWeight: 600, fontSize: "20px" }}>
                  {data.name}
                </Typography>
              </CardContent>
            </Card>
          );
        })}
      </Box>
     
    </Box>
  );
};

export default OurProduct;
