import React from "react";
import LayoutComponent from "../../Layout/LayoutComponent";
import { useSelector } from "react-redux";
import CommonFooter from "../Footer/CommonFooter";
import { Box, Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import routes from "../../AppRoutes/routes.json";

const AticleDetailed = () => {
  const { activeArticle } = useSelector((state) => state.CommonStore);
  console.log("active", activeArticle);

  function handleClick(event) {
    // event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  const breadcrumbs = [
    <Link
      key="1"
      style={{
        color: "#000000",
        textDecoration: "none",
        "&:hover": { textDecoration: "underline" },
      }}
      color="#000000"
      to={routes.Home}
      onClick={handleClick}
    >
      Home
    </Link>,
    <Link
      key="2"
      color="#000000"
      style={{
        color: "#000000",
        textDecoration: "none",
        "&:hover": { textDecoration: "underline" },
      }}
      to={routes.Articles}
      onClick={handleClick}
    >
      Articles
    </Link>,
    <Typography key="3" sx={{ color: "text.primary", fontWeight: 700 }}>
      Product Demo
    </Typography>,
  ];

  return (
    <LayoutComponent>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          paddingTop: 2,
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "90%", paddingLeft: 3 }}>
          <Breadcrumbs
            separator="›"
            aria-label="breadcrumb"
            sx={{ ".MuiBreadcrumbs-separator": { color: "red" } }}
          >
            {breadcrumbs}
          </Breadcrumbs>
        </Box>
        <Box
          sx={{
            width: "90%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "12px",
          }}
        >
          <img
            src={activeArticle.post_image}
            width="90%"
            height="600px"
            alt="articlepage"
            style={{ borderRadius: "12px" }}
          />
        </Box>
        <Box
          sx={{ width: "80%", justifyContent: "center", alignItems: "center" }}
        >
          {" "}
          <Typography sx={{ fontSize: "34px", fontWeight: 700,fontFamily:'DM Sans' }}>
            {activeArticle.post_title}
          </Typography>
        </Box>

        <Box sx={{ width: "90%" }}>
          <div
            dangerouslySetInnerHTML={{ __html: activeArticle.post_content }}
          />
        </Box>
      </Box>
      <CommonFooter />
    </LayoutComponent>
  );
};

export default AticleDetailed;
