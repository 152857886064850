import React, { useState } from "react";
import {useSelector } from "react-redux";
import {Box, Button, Typography} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import Download from "../../../Assets/download.png";

export const ProductSpecification = () => {
  const{productImages}= useSelector((state)=>state.CommonStore);
  console.log('product img: ', productImages)
  const{specification} = useSelector((state) => state.CommonStore);
  const [currentImg, setCurrentImg] = useState(productImages[0]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        // flexDirection:'row',
        flexWrap: "wrap",
        gap: "20px",
        justifyContent: "space-between",
        // alignItems:'center'
      }}
    >
      {/* First Div */}
      <Box
        sx={{
          width: "30%",
          maxWidth: "356px",
          height: "200px",
          color: "252525",
        }}
      >
        <Typography
          sx={{
            width: "360px",
            height: "44px",
            fontFamily: "DM Sans, sans-serif",
            fontWeight: 800,
            fontSize: "20px",
            lineHeight: "30px",
            color: "#252525",
          }}
        >
          61-9008 - 2015 Chevrolet Silverado 2500 HD steering gear
        </Typography>

        <hr
          style={{
            width: "349px",
            marginTop: "25px",
            color: "#3F3F3F",
          }}
        />

        <Typography
          sx={{
            width: "198px",
            height: "15px",
            fontFamily: "DM Sans, sans-serif",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "27px",
          }}
        >
          Product Description
        </Typography>
        <Typography
          sx={{
            width: "349px",
            height: "56px",
            fontFamily: "DM Sans, sans-serif",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "22px",
            marginTop: "10px",
          }}
        >
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's ...
        </Typography>
        <hr
          style={{
            width: "349px",
            marginTop: "25px",
            color: "#3F3F3F",
          }}
        />
        <Typography
          sx={{
            width: "111px",
            height: "11px",
            fontFamily: "DM Sans, sans-serif",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "27px",
            color: "#252525",
          }}
        >
          To Know More
        </Typography>
        {/* <Box
          sx={{
            width: "349px",
            height: "50px",
            border: "1px solid #EC1B24",
            borderRadius: "60px",
            backgroundColor: "#EC1B24",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <Button
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              width: "100%",
              height: "100%",
              backgroundColor: "transparent",
              border: "none",
              padding: "0",
              textTransform: "none",
              color: "#FFFFFF",
            }}
          >
            <PhoneIcon
              sx={{
                width: "22px",
                height: "18.33px",
                color: "#FFFFFF",
              }}
            />
            <span
              style={{
                fontFamily: "DM Sans, sans-serif",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "22px",
                color: "#FFFFFF",
              }}
            >
              Request a Callback
            </span>
          </Button>
        </Box> */}

        <Box
          sx={{
            width: "349px",
            height: "50px",
            border: "1px solid #EC1B24",
            borderRadius: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <Button
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              width: "100%",
              height: "100%",
              border: "none",
              padding: "0",
              textTransform: "none",
              color: "#FFFFFF",
            }}
          >
            <img
              src={Download}
              sx={{
                width: "22px",
                height: "18.33px",
                color: "#FFFFFF",
              }}
            />
            <span
              style={{
                fontFamily: "DM Sans, sans-serif",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "22px",
                color: "#EC1B24",
              }}
            >
              Download Brochure
            </span>
          </Button>
        </Box>
      </Box>

      {/* Second Div */}
      <Box
        sx={{
          width: "30%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "15px",
        }}
      >
        {/* Centered Image */}
        <Box
          sx={{
            width: "400px",
            height: "416px",
            borderRadius: "10px",
            border: "0.5px solid #ccc",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: "#F9F9F9",
            marginTop: "-25px",
          }}
        >
          { currentImg &&  currentImg.fileName ? (
            <img
              src={currentImg.fileName}
              style={{
                width: "100%",
                height: "100%",

                // width: "378px",
                // height: "324px",
                objectFit: "contain",
              }}
              alt={currentImg.img_id || "Image"}
            />
          ) : (
            <Typography
              sx={{
                fontFamily: "DM Sans, sans-serif",
                fontWeight: 600,
                fontSize: "18px",
                color: "#808080",
                textAlign: "center",
              }}
            >
              Photo Coming Soon
            </Typography>
          )}
        </Box>

        {/* Thumbnails */}
        <Box
          sx={{
            display: "flex",
            gap: "6px",
            width: "100%",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
        >
          {productImages && productImages.length > 1
            ? productImages.map((img) => {
                const isActive = currentImg.fileName === img.fileName;
                return (
                  <Box
                    key={img.img_id}
                    sx={{
                      width: "64.84px",
                      height: "49.28px",
                      border: isActive
                        ? "0.5px solid #EC1B24"
                        : "0.5px solid #808080",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding:'2px'
                    }}
                    onClick={() => setCurrentImg(img)}
                  >
                    <img
                      src={img.fileName}
                      alt={img.img_id}
                      style={{
                        width: "42.3px",
                        height: "42.3px",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                );
              })
            : null}
        </Box>
      </Box>

      {/* Third Div */}
      <Box
        sx={{
          width: "30%",
          // width: "501px",
          marginTop: "-30px",
          height:'auto',
          maxHeight: "500px",
          overflow: "hidden",
        }}
      >
        {specification && specification.length > 0 ? (
          <Box
            sx={{
              borderRadius: "13px",
              border: "1px solid #ccc",
              backgroundColor: "#EAEAEA",
            }}
          >
            <>
              {/* Header Section */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: "10px",
                  marginLeft: "2%",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "DM Sans, sans-serif",
                    fontWeight: 700,
                    fontSize: "16px",
                    color: "#55120B",
                    paddingTop: "20px",
                  }}
                >
                  Product Specification
                </Typography>
              </Box>

              {/* Table Section */}
              <Box
                sx={{
                  height: "auto",
                  maxHeight:'435px',
                  marginBottom:'10px',
                  overflowY: "auto",
                  overflowX: "hidden",
                  "&::-webkit-scrollbar": {
                    width: "3px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#EC1B24",
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "#D3D3D3",
                  },
                }}
              >
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    tableLayout: "fixed",
                  }}
                >
                  <tbody>
                    {specification.slice(0, 9).map((spec, index) => (
                      <tr
                        key={spec.PAID}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "#FFFFFF" : " #EAEAEA",
                          height: "50px",
                          color: "#252525",
                          fontSize: "14px",
                          fontWeight: 600,
                          fontFamily: "DM Sans, sans-serif",
                        }}
                      >
                        <td
                          style={{
                            textAlign: "left",
                            padding: "20px",
                          }}
                        >
                          {spec.product_specs_name}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            padding: "20px",
                          }}
                        >
                          {spec.product_specs_value}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Box>
            </>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
