import { Box, Button, Typography } from "@mui/material";
import React from "react";
import PwrReady from "../../Assets/PwrReady.png";
import GridViewIcon from "@mui/icons-material/GridView";

const ViewProducts = () => {
  return (
    <Box
      sx={{
        position: "relative",
        height: "442px",
        backgroundColor: "#000000",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box sx={{ position: "absolute", marginTop: -5 }}>
        <img
          src={PwrReady}
          alt="pwrReady"
          style={{ width: "231px", height: "183px" }}
        />
      </Box>
      <Box sx={{ marginTop: 20 }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "36px",
              color: "#EC1B24",
              fontFamily: "DM Sans, sans-serif",
            }}
          >
            When Your Reputation Is on the Line,
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "36px",
              color: "#ffffff",
              fontFamily: "DM Sans, sans-serif",
            }}
          >
            Choose ALL NEW Installation-Ready Power Steering
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 5,
          }}
        >
          <Typography
            sx={{ fontWeight: 400, fontSize: "14px", color: "#ffffff",   fontFamily: "DM Sans, sans-serif", }}
          >
            For unmatched reliability and easy installation, choose the all-new
            power steering solution.
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 4,
          }}
        >
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              width: "182px",
              height: "50px",
              borderRadius: "6100px",
              backgroundColor: "#333333",
              fontFamily: "DM Sans, sans-serif",
              fontWeight:500,
              fontSize:'14px',
              color:'#D5D7D6'
            }}
            startIcon={<GridViewIcon sx={{ color: "#EC1B24" }} />}
          >
            View Products
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ViewProducts;
