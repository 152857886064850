import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import Play from "../../Assets/HeaderIcon/Youtube.png"

const VideoPlayer = ({ videoUrl, thumbnail, index,isPlaying,onPlay }) => {
//   const [isPlaying, setIsPlaying] = useState(false);
  const [clickedIndex,setClickedindex]=useState()

  // Extract YouTube video ID from the URL
  const videoId = videoUrl.split("v=")[1]?.split("&")[0];

  return (
    <div
      style={{
        Width: "100%",
        padding: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {!isPlaying ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            position:'relative'
          }}
          onClick={onPlay}
        >
          <img
            src={
              thumbnail ||
              `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
            }
            alt="Thumbnail"
            style={{
              width: "500px",
              borderTopLeftRadius: index === 0 ? "12px" : "0px",
              borderBottomLeftRadius: index === 0 ? "12px" : "0px",
              borderTopRightRadius: index === 2 ? "12px" : "0px",
              borderBottomRightRadius: index === 2 ? "12px" : "0px",
              height: "300px",
            }}
          />
          <div style={{position:'absolute'}}>
            <img src={Play}/>
          </div>

        </div>
      ) : (
        <iframe
          width="500px"
          height="300px"
          style={{ borderRadius: "12px" }}
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
    </div>
  );
};

const ExpertTips = () => {
    const [playingIndex, setPlayingIndex] = useState(null);
  const vedioArr = [
    {
      url: "https://www.youtube.com/watch?v=KUidgBJVmRk&t=42s",
      title: "Installing Rack and Pinion",
    },
    {
      url: "https://www.youtube.com/watch?v=blVFoA_iUhg",
      title: "Changing out power steering box",
    },
    {
      url: "https://www.youtube.com/watch?v=gjeacN6sK78",
      title: "Replacing a power steering pump",
    },
  ];
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 10,
        gap: 4,
      }}
    >
      <Box>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "36px",
            fontFamily: "DM Sans, sans-serif",
            color: "#55120B",
          }}
        >
          Expert Tips & Tutorials
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        {vedioArr.map((video, index) => {
          return (
            <div
              key={index}
              style={{
                width: "30%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
              }}
            >
              <VideoPlayer videoUrl={video.url} index={index}isPlaying={playingIndex === index}
            onPlay={() => setPlayingIndex(index)} />
              <Typography sx={{fontWeight:600,fontSize:'16px',fontFamily:"DM Sans, sans-serif"}}>{video.title}</Typography>
            </div>
          );
        })}
      </Box>
    </Box>
  );
};

export default ExpertTips;
