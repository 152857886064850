import React from 'react'
import { CommonSetupActions } from '../../../Slices/Commonslice';
import routes from "../../../AppRoutes/routes.json"

export const getProductAppData = (partNo,navigate) => {
  return (dispatch, getState) =>{
    try{
      console.log('getProductAppData:',partNo)
        const link = "https://newdb.pwrsteer.com/api";

        // const link ="https://de3e-45-115-56-241.ngrok-free.app/api";

        fetch(link + "/websiteProductPage/" + partNo, {
            method: "GET",
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
          })
            .then((resp) => {
              console.log("product display...status", resp);
              return resp.json();
            })
            .then((response) => {
              console.log("response", response);
              console.log('app res:: ', response.applications)
              dispatch(CommonSetupActions.setApplication(response.applications));
              dispatch(CommonSetupActions.setProductImages(response.imageInfo));
              dispatch(CommonSetupActions.setBulletinsPdf(response.techBulletinsPDF));
              dispatch(CommonSetupActions.setSpecification(response.product_specs));
              dispatch(CommonSetupActions.setInterchange(response.interchanges));
              navigate(routes.GearBox)
              // setLoading(false);
      
            });
          } catch(err){
        console.log(err);
    }
  }
}
