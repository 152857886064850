import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box, Button, Typography, CircularProgress,} from "@mui/material";
import routes from "../../../AppRoutes/routes.json";
import CommonFooter from "../../Footer/CommonFooter";
import LayoutComponent from "../../../Layout/LayoutComponent";
import { ProductSpecification } from "./ProductSpecification";
import { Application } from "./Application";
import { PackageProductBulletin } from "./PackageProductBulletin";
import { CompetitorInterchange } from "./CompetitorInterchange";
import { getProductAppData } from "./GearBoxAction";

export const GearBox = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const[loading, setLoading]= useState(false);
  let partNo = "60-5001R";
  // let partNo = "60-6841P";

  return (
    <LayoutComponent>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          padding: "80px",
          boxSizing: "border-box",
        }}
      >
        {/* nav bar code starts here */}
        <Box
          sx={{
            width: "100%",
            height: "10px",
            top: "214px",
            border: "1px",
            marginBottom: "40px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: "#555",
              display: "flex",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <Typography
              onClick={() => navigate(routes.Home)}
              sx={{
                width: "40px",
                height: "10px",
                fontFamily: "DM Sans, sans-serif",
                fontWeight: 400,
                lineHeight: "27px",
                textAlign: "center",
                color: "#565656",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              Home
            </Typography>
            <span
              style={{
                width: "7px",
                height: "3.5px",
                color: "#EC1B24",
              }}
            >
              &gt;
            </span>
            <Typography
              onClick={() => navigate(routes.GearBox)}
              sx={{
                width: "69px",
                height: "10px",
                color: "#000000",
                fontFamily: "DM Sans, sans-serif",
                textDecoration: "none",
                lineHeight: "27px",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              Gear Box
            </Typography>
          </Typography>
        </Box>
        {/* nav bar code ends here */}

        {/* Main Content */}
        {loading ? (
        // Show CircularProgress loader while loading
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress sx={{ color: "#EC1B24" }} />
        </Box>
      ) :(
        <Box sx={{ display: "flex", flexDirection: "column", gap: "40px" }}>
          {/*  First Row: 3 divs */}
          <ProductSpecification />

          {/* Second Row: Single Table */}
          
            <Application />
          {/* Third Row */}
          <Box
            sx={{
              display: "flex",
              flexDirection:'row',
              gap: "20px",
            }}
          >
            {/* First Column */}
            <PackageProductBulletin />

            {/* Second Column */}
            <CompetitorInterchange />
          </Box>
        </Box>
      )
        }

      </Box>

    
      <CommonFooter />
    </LayoutComponent>
  );
};
