import React from "react";
import { Box, Button, Typography } from "@mui/material";
import AboutUsSlider from "../../Assets/About/aboutSlider.png";
import Vector from "../../Assets/About/Vector.png";
import LayoutComponent from "../../Layout/LayoutComponent";
import DownArrow from "@mui/icons-material/KeyboardArrowDown";
import ViewIcon from "../../Assets/About/Group 5925.png";
import CommonFooter from "../Footer/CommonFooter";
import Repair1 from "../../Assets/jkl-transformed.png";
import Repair2 from "../../Assets/About/image.png";

const AboutPwr = () => (
  <LayoutComponent>
    <Box sx={{ position: "relative", width: "100%", height: "2800px" }}>
      {/* Image */}
      <img
        src={AboutUsSlider}
        alt="About Us"
        style={{
          width: "100%",
          height: "540.36px",
          objectFit: "cover",
        }}
      />

      <Box
        sx={{
          width: "335px",
          height: "234px",
          position: "absolute",
          top: "276px",
          left: "340px",
          transform: "translate(-50%, -50%)",
          color: "white",
          textAlign: "center",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontWeight: "700",
            size: "60px",
            alignItems: "center",
            textAlign: "center",
            lineHeight: "27px",
            width: "332",
            height: "42px",
            top: "286px",
            left: "92px",
            fontFamily:'DM Sans, sans-serif'
          }}
        >
          About <span style={{ color: "#EC1B24" }}> PWR </span>
        </Typography>
        <img
          src={Vector}
          style={{ width: 332, marginTop: "20px", left: 92, border: 4 }}
        />
        <Typography
          variant="body2"
          sx={{
            width: "335px",
            height: "32px",
            marginTop: "20px",
            textAlign: "left",
            fontFamily: "DM Sans, sans-serif",
          }}
        >
          Built to Last, Driven to Lead: Innovation, Precision, and Power
          Steering Excellence{" "}
        </Typography>
      </Box>

      <Box
        sx={{
          top: "420px",
          position: "absolute",
          left: "97%",
          transform: "translateX(-50%)",
          width: "34px",
          height: "60px",
          boxSizing: "border-box",
          border: "1px solid #FFFFFF",
          borderRadius: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DownArrow
          sx={{
            paddingTop: "90%",
            border: "1.6px",
            color: "#EC1B24",
          }}
        ></DownArrow>
      </Box>

      {/* 2nd box(row) */}
      <Box
        sx={{
          width:'90%',
          height: "408px",
          boxSizing: "border-box",
          marginTop: "px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          margin: "120px",
          gap:2,
        }}
      >
        {/* Left side content box */}
        <Box
          sx={{
            width: "40%",
          }}
        >
          <Typography
            sx={{
              width: "450px",
              height: "70px",
              fontWeight: 600,
              fontSize: "30px",
              lineHeight: "40px",
              color: "#EC1B24",
              fontFamily: "DM Sans, sans-serif",
            }}
          >
            When Your Reputation is on the Line,
          </Typography>

          <Typography
            sx={{
              width: "454px",
              height: "69px",
              marginTop: "40px",
              fontWeight: "600px",
              fontSize: "30px",
              lineHeight: "40px",
              fontFamily: "DM Sans, sans-serif",
            }}
          >
            Choose ALL NEW Installation Ready Power Steering
          </Typography>

          <Typography
            sx={{
              width: "397px",
              height: "33px",
              top: "914px",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "23px",
              color: "#8A8A8A",
              marginTop: "25px",
              fontFamily: "DM Sans, sans-serif",
            }}
          >
            For unmatched reliability and easy installation, choose the all-new
            power steering solution.
          </Typography>
          <Box
            sx={{
              boxSizing: "border-box",
              width: "182px",
              height: "50px",
              marginTop: "60px",
              borderRadius: "6100px",
              backgroundColor: "#0E1415",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              sx={{
                textTransform: "none",
                display: "flex",
                alignItems: "center",
                padding: 0,
              }}
            >
              <img
                src={ViewIcon}
                alt="View Icon"
                style={{
                  width: "16px",
                  height: "16px",
                  color: "#EC1B24",
                  marginRight: "10px",
                }}
              />
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "12px",
                  color: "#D5D7D6",
                  fontFamily: "DM Sans, sans-serif",
                }}
              >
                View Products
              </span>
            </Button>
          </Box>
        </Box>

        {/* Right Content box */}
        <Box
          sx={{
            width: "50%",
            height: "430px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "25px",
              fontFamily: "DM Sans, sans-serif",
            }}
          >
            PWR Steer, a division of Premium Guard Inc., was established in 2018
            with a clear vision: to set a new standard for aftermarket power
            steering solutions by providing innovative, high-quality products
            engineered for reliability and seamless performance. As a pioneer in
            introducing 100% new, first-to-market power steering products, PWR
            Steer eliminates the common issues associated with remanufactured
            parts, offering a dependable alternative that technicians and
            customers can trust.
          </Typography>
          <br />
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "25px",
              fontFamily: "DM Sans, sans-serif",
            }}
          >
            Our commitment to excellence is reflected in every aspect of our
            product line, which includes power steering pumps, racks, pinions,
            gearboxes, and reservoirs, each designed and manufactured to meet or
            exceed OEM specifications. Through strategic collaborations with
            Tier 1 OE manufacturers and a dedication to rigorous quality control
            standards, PWR Steer products are crafted to ensure high durability,
            reliability, and ease of installation. Utilizing advanced
            manufacturing processes that adhere to ISO/TS/OHSA certifications,
            PWR Steer guarantees that each part upholds the highest industry
            standards, reducing failure rates and installation times
            significantly.
          </Typography>
          <br />
          <Typography
            sx={{
              fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "25px",
              fontFamily: "DM Sans, sans-serif",
            }}
          >
            Our exclusive features, such as integral and remote reservoirs,
            OE-approved sensors, and pre-installed pulleys, cater to the demands
            of modern vehicles. These precision-engineered components help
            reduce labor costs and provide a secure fit, while our specially
            designed packaging minimizes damage during transit. With a return
            rate consistently at or below 3%, PWR Steer’s products underscore
            our commitment to quality, delivering solutions that reduce warranty
            claims and enhance customer satisfaction.
          </Typography>
        </Box>
      </Box>

      {/* 3rd row */}
      <Box
        sx={{
          width:'100%',
          backgroundColor: "#F9F9F9",
          height: "1558px",
          boxSizing: "border-box",
          marginTop:'100px'
        }}
      >
        <Box
          sx={{
            margin: "100px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            paddingTop: "60px",
            gap:10
          }}
        >
          {/* image box */}
          <Box
            sx={{
              width: "50%",
            }}
          >
            <img
              src={Repair1}
              alt="JKL"
              style={{
                width: "646px",
                height: "804px",
                borderRadius: "13px",
              }}
            />
          </Box>

          {/* content box */}
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "377px",
                color: "#0B0F11",
              }}
            >
              <Typography
                sx={{
                  width: "290px",
                  height: "17px",
                  fontWeight: "700",
                  fontSize: "24px",
                  lineHeight: "22px",
                  fontFamily: "DM Sans, sans-serif",
                }}
              >
                Innovative Product Line
              </Typography>
              <br />
              <Box
                sx={{
                  width: "650px",
                  height: "330px",
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "25px",
                  fontFamily: "DM Sans, sans-serif",
                }}
              >
                <Typography sx={{ fontFamily: "DM Sans",fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "25px", }}>
                  Every PWR Steer product is 100% new, eliminating the quality
                  risks associated with remanufactured parts. Our pumps, for
                  example, come equipped with OE-specific pulleys, reservoirs,
                  and sensors where applicable, ensuring that each part is
                  tailored to meet precise vehicle requirements. PWR Steer racks
                  and pinions are manufactured with OE-grade materials and
                  include integral mounting bushings and exact transfer tubes,
                  delivering the closest fit to original parts available in the
                  aftermarket.
                </Typography>
                <br />
                <Typography sx={{ fontFamily: "DM Sans, sans-serif",fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "25px", }}>
                  We continue to lead with product innovations, such as our
                  Exclusive PR and PXR Pump models, which include pre-installed
                  or external mounted reservoirs and pulleys designed to
                  simplify installation. These products offer unique value by
                  providing technicians with all necessary components in one
                  box, minimising the need for additional parts and ensuring a
                  smoother installation process. Our gearboxes feature 100% new
                  housings and internal components, backed by
                  corrosion-resistant coatings and protective packaging, making
                  them as durable as they are dependable.
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                width: "650px",
                height: "352px",
                color: "#0B0F11",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "25px",
                marginTop: "50px",
                fontFamily: "DM Sans, sans-serif",
              }}
            >
              <Typography
                sx={{
                  width: "374px",
                  height: "16px",
                  fontWeight: 700,
                  fontSize: "24px",
                  lineHeight: "22px",
                  fontFamily: "DM Sans, sans-serif",
                }}
              >
                About Premium Guard Inc.
              </Typography>
              <br />
              <Typography sx={{ fontFamily: "DM Sans, sans-serif",fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "25px", }}>
                Premium Guard Inc. (PGI), founded in 1996, is a respected name
                in the automotive aftermarket, known for pioneering quality
                manufacturing and product excellence. PGI’s mission is to
                provide turnkey solutions with comprehensive application
                coverage, enabling our partners to succeed in all segments of
                the automotive aftermarket across North America. Leveraging over
                25 years of expertise, PGI specialises in private label programs
                of the highest quality, delivering performance and reliability
                that customers depend on.
              </Typography>
              <br />
              <Typography sx={{ fontFamily: "DM Sans, sans-serif",fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "25px", }}>
                With a strong focus on integrity, team unity, and operational
                excellence, PGI fosters a culture of continuous improvement that
                drives our commitment to customers. This philosophy extends to
                our rigorous ISO 9001:2015 certified quality management system,
                which emphasises defect prevention and minimises waste and
                inefficiencies throughout the production and distribution
                process. From manufacturing to delivery, PGI’s high standards
                help ensure that every product embodies our dedication to
                quality.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            margin: "100px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          {/* content box */}
          <Box
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "182px",
                color: "#0B0F11",
              }}
            >
              <Typography
                sx={{
                  width: "386px",
                  height: "17px",
                  fontWeight: "700",
                  fontSize: "24px",
                  lineHeight: "22px",
                  fontFamily: "DM Sans, sans-serif",
                }}
              >
                Our Values and Vision
              </Typography>
              <br />
              <Box
                sx={{
                  width: "638px",
                  height: "135px",
                }}
              >
                <Typography
                  sx={{
                    marginTop: "10px",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "25px",
                    fontFamily: "DM Sans, sans-serif",
                  }}
                >
                  Premium Guard, Inc. is built on a foundation of core values
                  that guide everything we do: integrity, customer-centricity,
                  and commitment to operational excellence. We strive to build a
                  high-performance culture that challenges us to improve
                  constantly, go the extra mile, and lead by example. PGI’s
                  vision is to offer our customers unmatched service,
                  industry-leading product coverage, and best-in-class quality
                  at competitive prices, ensuring they have a distinct advantage
                  in today’s demanding market.
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "302px",
                color: "#0B0F11",
                marginTop: "50px",
              }}
            >
              <Typography
                sx={{
                  width: "483px",
                  height: "17px",
                  fontWeight: "700",
                  fontSize: "24px",
                  lineHeight: "22px",
                  fontFamily: "DM Sans, sans-serif",
                }}
              >
                Customer Commitment and Support
              </Typography>
              <br />
              <Box
                sx={{
                  width: "638px",
                  height: "255px",
                  marginTop: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "25px",
                    fontFamily: "DM Sans, sans-serif",
                  }}
                >
                  Customer satisfaction is central to PWR Steer's values and to
                  PGI’s longstanding commitment to the aftermarket industry. Our
                  ASE-certified support team is readily available by phone and
                  online, offering expert assistance with technical inquiries
                  and product guidance. This dedication to service makes PWR
                  Steer the trusted choice of professionals and DIY enthusiasts
                  alike. With product coverage spanning over 74% of vehicles on
                  the road today, PWR Steer enables customers to expand their
                  market presence, optimize their offerings, and enhance
                  profitability with confidence.
                  <br />
                  <br />
                  For premium quality, ease of installation, and dependable
                  customer support, choose PWR Steer – the power steering
                  solution trusted by industry professionals and backed by PGI’s
                  legacy of excellence.
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* image box */}
          <Box
            sx={{
              width: "50%",
              marginLeft:'6%'
            }}
          >
            <img
              src={Repair2}
              alt="JKL"
              style={{
                width: "603px",
                height: "534px",
                borderRadius: "13px",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
    <CommonFooter />
  </LayoutComponent>
);

export default AboutPwr;
