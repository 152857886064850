import React, { useRef } from "react";
import LayoutComponent from "../../Layout/LayoutComponent";
import { Box, Typography } from "@mui/material";
import PwrProductSearch from "./PwrProductSearch";
import OurProduct from "./OurProduct";
import ReliabilityAndQuality from "./ReliabilityAndQuality";
import ViewProducts from "./ViewProducts";
import HomeSwiper from "./HomeSwiper";
import CommonFooter from "../Footer/CommonFooter";
import ExpertTips from "./ExpertTips";
import { FrequentlyAskedQuest } from "./FrequentlyAskedQuest";
import {PartNoModal} from "./PartNoModal";

const HomePage = () => {
  const getInTouchRef = useRef(null);

  // Function to scroll to the GetInTouchForm component
  const scrollToGetInTouch = () => {
    getInTouchRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const [open, setOpen] = React.useState(false);

  return (
    <LayoutComponent scrollToGetInTouch={scrollToGetInTouch}>
      <PwrProductSearch />
      <OurProduct />
      <ReliabilityAndQuality />
      <ViewProducts />
      <HomeSwiper />
      <ExpertTips/>
      <div ref={getInTouchRef}><FrequentlyAskedQuest />
      <PartNoModal open={false} setOpen={setOpen} /></div>
      
      <CommonFooter />
    </LayoutComponent>
  );
};

export default HomePage;
