import React, { useState } from "react";
import LayoutComponent from "../../Layout/LayoutComponent";
import CommonFooter from "../Footer/CommonFooter";
import { Box, Button, Typography } from "@mui/material";
import EnglishComp from "../Warranty/EnglishWarrantyStat";
import FrenchComp from "../Warranty/FrenchWarrantyStat";
import SpanishComp from "../Warranty/SpanishWarrantyStat";

export const WarrantyStatement = () => {
  const [language, setLanguage] = useState([
    {
      title: "English",
      active:true
    },
    {
      title: "French",
      active:false
    },
    {
      title: "Spanish",
      active:false
    },
  ]);

  const handleClick=(index)=>{
    console.log('index',index)
    setLanguage((prevLang) =>
      prevLang.map((lang,indx)=>({
        ...lang,
        active: indx === index,
      }))
    );
    console.log('update lang:: ', language)
  }

  const activeLang = language.find((lang) => lang.active)?.title;

  return (
    <LayoutComponent>
      <Box sx={{ position: "relative", width: "100%", height: "1290px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            boxSizing: "border-box",
            // border: "2px solid red",
            margin: "70px",
          }}
        >
          {/* warranty statement heading */}
          <Box
            sx={{
              // width:'909px',
              height: "42px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "DM Sans, sans-serif",
                fontSize: "60px",
                fontWeight: 800,
                lineHeight: "27px",
                color: "#000000",
              }}
            >
              Warranty Statement
            </Typography>
            <hr
              style={{
                width: "100%",
                marginTop: "46px",
                // border: "0.7px solid #D9D9D9",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              marginTop: "120px",
              gap: 2
            }}
          >
            <Box
              sx={{
                width: "40%",
                // border: "2px solid green",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              {language.map((lang, index) => {
                return (
                  <Box key={index} sx={{
                  }}>
                    <Button variant="contained" sx={{
                    width:'429px',
                    height:'62px',
                    borderRadius:'9px',
                    // border:'0.5px solid #BDBDBD',
                    borderLeft: lang.active ? "9px solid #EC1B24" : "9px solid transparent",
                    justifyContent:'flex-start',
                    marginBottom:'15px',
                    backgroundColor:'#FAFAFA',
                    color:'#252525',
                    fontSize:'16px',
                    fontFamily:"DM Sans, sans-serif",
                    fontWeight:700,
                    lineHeight:'27px',
                    textTransform:'none'
                  }} onClick={() => handleClick(index)}>{lang.title}</Button>
                  </Box>
                );
              })}
            </Box>
            <Box
              sx={{
                width: "60%",
                height: "870px",
                // border: "2px solid blue",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              {activeLang === "English" && <EnglishComp /> }
              {activeLang === "French" && <FrenchComp /> }
              {activeLang === "Spanish" && <SpanishComp /> }

            </Box>
          </Box>
        </Box>
      </Box>
      <CommonFooter />
    </LayoutComponent>
  );
};
