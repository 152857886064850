import React, { useEffect, useState, useRef } from "react";
import { Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import routes from "../../AppRoutes/routes.json";

import videoPlayerIcon from "../../Assets/Rack&Pinions/videoPlayerIcon.png";

const useStyles = {
  customScrollbar: {
    overflowY: "auto",
    height: "581px",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ec1b24",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#d1191f",
    },
    "&::-webkit-scrollbar-button": {
      display: "none",
      width: "0",
      height: "0",
    },
  },
};

const CommonVideos = () => {
  const [videoArr, setVideoArr] = useState();
  const [activeBlog, setActiveBlog] = useState();
  const titleRef = useRef(null);
  const [hrWidth, setHrWidth] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);

  const descRef = useRef(null);

  const location = useLocation();
  const pathName = location.pathname;
  const path = pathName.split("/").pop();
  console.log("pathName:", pathName);
  console.log("routes.video:", routes.video);
  let productId;
  if (pathName === routes.SteeringPump) {
    productId = 1;
  } else if (pathName === routes.RackPinion) {
    productId = 2;
  } else if (pathName === routes.SteeringGearBox) {
    productId = 3;
  }

  const link =
    pathName === routes.video
      ? "https://newdb.pwrsteer.com/api/videos"
      : `https://newdb.pwrsteer.com/api/videos/${productId}`;

  useEffect(() => {
    try {
      fetch(link, {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
        .then((resp) => resp.json())
        .then((resp) => {
          console.log("res: ", resp);
          const formattedData = resp.map((item) => ({
            videoId: item.video_id,
            productId: item.product_video_id,
            videoName: item.video_name,
            videoLink: item.video_link,
            videoDesc: item.videos_description,
            thumbnail: item.videothumbnail,
            createdDate: item.created_date,
            newDate: item.new_date,
          }));
          setVideoArr(formattedData);
          setActiveBlog(formattedData[0]);
        });
    } catch (err) {
      console.error("Error fetching videos:", err);
    }
  }, [link]);

  useEffect(() => {
    if (titleRef.current) {
      setHrWidth(titleRef.current.offsetWidth);
    }

    if (descRef.current) {
      const lineHeight = 22;
      const maxHeight = lineHeight * 2; 
      const textHeight = descRef.current.scrollHeight;

      setIsTruncated(textHeight > maxHeight);
    }
  }, [activeBlog]);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  console.log("hr width",hrWidth);
  return (
    <Box
      sx={{
        width: "100%",
        height: "750px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        border:
          pathName === routes.Videos
            ? "0.5px solid #FFFFFF"
            : "0.5px solid #ccc",
        backgroundColor: pathName === routes.Videos ? "#FFFFFF" : "#F9F9F9",
        borderRadius: "12px",
        gap: 2,
      }}
    >
      {/* Left Box */}
      <Box
        sx={{
          width: "50%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          borderRight:
            pathName === routes.Videos
              ? "0.5px solid #FFFFFF"
              : "0.5px solid #ccc",
          paddingLeft: "40px",
          paddingRight: "30px",
        }}
      >
        <Typography
          sx={{
            width: "100%",
            marginBottom: pathName === routes.Videos ? "5px" : "25px",
            fontFamily: "DM Sans",
            fontWeight: 700,
            fontSize: pathName === routes.Videos ? "34px" : "19px",
            lineHeight: "27px",
            color: "#000000",
            marginTop: "60px",
          }}
        >
          {pathName === routes.Videos ? "Recent Videos" : "Watch Video"}
        </Typography>

        {pathName === routes.Videos && (
          <hr
            style={{
              width: "240px",
              height: "4px",
              marginLeft: "0",
              backgroundColor: "#E30000",
              border: "none",
              borderRadius: "5px",
              marginBottom: "40px",
            }}
          />
        )}

        <Box
          sx={{
            width: "60%",
            height: "60%",
            borderRadius: "13px",
          }}
        >
          <iframe
            width="700px"
            height="400px"
            style={{ borderRadius: "12px" }}
            src={activeBlog && activeBlog.videoLink}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Box>
        {pathName === routes.Videos ? (
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              fontFamily: "DM Sans",
              lineHeight: "22px",
              color: "#000000",
              textAlign: "right",
              marginLeft: "600px",
              marginTop: "-40px",
            }}
          >
            {activeBlog && activeBlog.newDate}
          </Typography>
        ) : null}
        <Typography
          ref={titleRef}
          sx={{
            fontSize: "30px",
            fontFamily: "DM Sans",
            lineHeight: "27px",
            color: "#000000",
            marginTop: "20px",
          }}
        >
          {activeBlog && activeBlog.videoName}
        </Typography>

        <hr
          style={{
            marginTop: "10px",
            width: `${hrWidth}px`,
            maxWidth: "450px",
            height: "4px",
            backgroundColor: "#E30000",
            border: "none",
            borderRadius: "5px",
            marginLeft: 0,
          }}
        />

<Typography
          ref={descRef}
          sx={{
            width: "550px",
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "22px",
            color: "#252525",
            display: "-webkit-box",
            WebkitLineClamp: isExpanded ? "unset" : 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            wordWrap: "break-word",
          }}
        >
          {activeBlog && activeBlog.videoDesc}

          
        </Typography>
        {isTruncated && !isExpanded && (
            <span
              style={{
                fontWeight: 700,
                fontSize: "16px",
                color: "#EF3840",
                cursor: "pointer",
              }}
              onClick={handleToggle}
            >
              Read More
            </span>
          )}
          {isExpanded && (
            <span
              style={{
                fontWeight: 700,
                fontSize: "16px",
                color: "#EF3840",
                cursor: "pointer",
              }}
              onClick={handleToggle}
            >
              Show Less
            </span>
          )}
      </Box>

      {/* Right Box */}
      <Box
        sx={{
          width: "50%",
          height: "90%",
          boxSizing: "border-box",
          marginTop: "60px",
          marginLeft: "30px",
          marginRight: "50px",
        }}
      >
        <Box
          sx={{
            ...useStyles.customScrollbar,
            width: "100%",
            height: "630px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            marginTop: "60px",
          }}
        >
          {videoArr &&
            videoArr.map((video, index) => (
              <Box
                key={index}
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 4,
                  marginBottom: "40px",
                  cursor: "pointer",
                }}
                onClick={() => setActiveBlog(video)}
              >
                {/* Thumbnail with video player icon */}
                <Box
                  sx={{
                    width: "231px",
                    height: "171px",
                    borderRadius: "10px",
                    overflow: "hidden",
                    flexShrink: 0,
                    position: "relative",
                  }}
                >
                  <img
                    src={video.thumbnail}
                    alt="blog"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                  />
                  <img
                    src={videoPlayerIcon}
                    alt="play icon"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "42px",
                      height: "42px",
                      pointerEvents: "none",
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    width: "calc(100% - 140px)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "20px",
                      fontFamily: "DM Sans, sans-serif",
                      lineHeight: "27px",
                      color: "#0E1415",
                      wordBreak: "break-word",
                    }}
                  >
                    {video.videoName}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "DM Sans, sans-serif",
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "22px",
                      color: "#0B0F11",
                      marginTop: "10px",
                      maxHeight: "44px",
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {video.videoDesc}
                  </Typography>
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default CommonVideos;
