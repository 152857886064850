import logo from './logo.svg';
import './App.css';
import AppRoutes from './AppRoutes/AppRoutes';
import { Provider } from 'react-redux';
import Store from './Store/store';

function App() {
  return (
  <Provider store={Store}>
  <AppRoutes/>
  </Provider>
  )
}

export default App;
