import React, { useState, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import FooterImg from "../../Assets/Home/Group 5959.png";
import "../Home/HomeSwiperCss.css";
import cardIcon1 from "../../Assets/Home/cardIcon1.png";
import cardIcon2 from "../../Assets/Home/cardIcon2.png";
import cardIcon3 from "../../Assets/Home/cardIcon3.png";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import SendIcon from "@mui/icons-material/Send";
import routes from "../../AppRoutes/routes.json"
import { useNavigate } from "react-router-dom";

const HomeSwiper = () => {
  const [cardIndex, setCardIndex] = useState(1);
  const navigate =useNavigate()
  const sliderRef = useRef(null);
  const [cards, setCards] = useState([
    {
      id: 1,
      image: cardIcon1,
      title: "First-to-Market",
      desc: "Stay ahead with PWR Steer. We lead the industry in bringing coverage for late-model applications, ensuring you always have access to the most up-to-date solutions for modern vehicles.",
    },
    {
      id: 2,
      image: cardIcon2,
      title: "Crafted with Precision",
      desc: "Crafted with precision, every PWR Steer product meets or exceeds OE quality standards, giving you the dependable performance that professionals demand. Built to last, trusted by experts.",
    },
    {
      id: 3,
      image: cardIcon3,
      title: "Installation Ready",
      desc: "PWR Steer products come fully equipped for a hassle-free installation, packed with everything you need to complete the job on the first try. No missing pieces, no delays—just straightforward solutions that save you time and money, delivering exceptional fit and function.",
    },
    {
      id: 4,
      image: cardIcon1,
      title: "First-to-Market",
      desc: "Stay ahead with PWR Steer. We lead the industry in bringing coverage for late-model applications, ensuring you always have access to the most up-to-date solutions for modern vehicles.",
    },
    {
      id: 5,
      image: cardIcon2,
      title: "Crafted with Precision",
      desc: "Crafted with precision, every PWR Steer product meets or exceeds OE quality standards, giving you the dependable performance that professionals demand. Built to last, trusted by experts.",
    },
    {
      id: 6,
      image: cardIcon3,
      title: "Installation Ready",
      desc: "PWR Steer products come fully equipped for a hassle-free installation, packed with everything you need to complete the job on the first try. No missing pieces, no delays—just straightforward solutions that save you time and money, delivering exceptional fit and function.",
    }
  ]);

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0",
    focusOnSelect: true,
    initialSlide: 1,
    beforeChange: (oldIndex, newIndex) => {
      console.log("old: ", oldIndex, "new: ", newIndex);
      setCardIndex(newIndex);
    },
  };

  return (
    <>
      {/* Main Container */}
      <Box
        sx={{
          width: "100%",
          height: "1016px",
          top: "3105px",
          backgroundColor: "#FFFFFF",
        }}
      >
        {/* Slider Section */}
        <Box
          className="slider-box"
          sx={{
            width: "100%",
            height: "637px",
            top: "3105px",
            backgroundColor: "#FFFFFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "110px",
          }}
        >
          <Box
            className="slider-div-cont"
            sx={{
              height: "181px",
              top: "3105px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "800px",
                fontSize: "40px",
                lineHeight: "50.8px",
                textAlign: "center",
                color: "#55120B",
                fontFamily: "DM Sans, sans-serif",

              }}
            >
              Your Trusted Source for
              <br />
              <span
                style={{
                  color: "#EC1B24",
                  fontFamily: "DM Sans, sans-serif",
                }}
              >
                New Power Steering Products
              </span>
            </Typography>
            <Box
              sx={{
                width: "716px",
                height: "56px",
                top: "3214px",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 2,
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "400px",
                  size: "14px",
                  lineHeight: "23px",
                  textAlign: "center",
                  color: "#333333",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "DM Sans, sans-serif",
                }}
              >
                PWR STEER's new power steering racks, pumps, and gear boxes are
                designed to improve operational efficiency and are supplied as
                installation ready to further address historic quality issues.
                Experience the unmatched confidence of USA engineering with PWR
                Steer products.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: "139.5px",
              height: "20px",
              top: "3300px",
              borderRadius: "100px",
              backgroundColor: "#EC1B24",
              paddingTop: "10px",
              paddingRight: "40px",
              paddingBottom: "18px",
              paddingLeft: "0",
              gap: "10px",
              marginBottom: "30px",
              marginTop: "40px",
            }}
          >
            <Button
              onClick={()=>navigate(routes.About)}
              sx={{
                width: "184px",
                height: "30px",
                fontWeight: "500",
                fontSize: "17px",
                lineHeight: "12px",
                color: "#FFFFFF",
                textAlgin: 'center',
                textTransform: 'none',               
                     fontFamily: "DM Sans, sans-serif",
              }}
            >
              Learn More
              {/* <Typography sx={{ textTransform: 'none',                    fontFamily: "DM Sans, sans-serif",
 }}>Learn More</Typography> */}
              <ArrowRightAltIcon
                sx={{
                  width: "20.5px",
                  border: "1px",
                  color: "#55120B",
                }}
              />
            </Button>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              direction: "row",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            <Box
              sx={{
                width: "60%",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Slider ref={sliderRef} {...settings}>
                {cards.map((card, index) => {
                  const isActive = index === cardIndex;
                  const isBefore = index === cardIndex - 1;
                  const isAfter = index === cardIndex + 1;

                  return (
                    <Box
                      key={card.id}
                      className={isActive ? "slide activeSlide" : "slide"}
                      sx={{
                        width: isActive ? "331" : "338px",
                        height: isActive ? "392px" : "334px",
                        marginTop: isActive ? "20px" : "35px",
                        textAlign: "center",
                        cursor:'pointer',
                        background: isActive
                          ? "linear-gradient(100deg, #860F14, #EC1B24)"
                          : "#000000",
                        color: isActive ? "#fff" : "#ccc",
                        padding: "10px",
                        borderRadius: isActive
                          ? "10px"
                          : isBefore
                          ? "10px 0 0 10px"
                          : isAfter
                          ? "0 10px 10px 0"
                          : "10px",
                        boxSizing: "border-box",
                        transform: isActive
                          ? "translateY(0)"
                          : isBefore || isAfter
                          ? "translateY(20px)"
                          : "translateY(20px)",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      {/* Inner Box for Content */}
                      <Box
                        sx={{
                          width: "100%",
                          height: "190px",
                          // margin: "0 auto",
                          display: "coloumn",
                          direction: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",

                          paddingTop: isActive ? "70px" : "20px",
                        }}
                      >
                        {/* Image */}
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                            padding: "5px",
                          }}
                        >
                          <img
                            src={card.image}
                            className="card-img"
                            style={{
                              width: "32.38px",
                              height: "42px",
                              marginLeft: "10px",
                            }}
                            alt={card.title}
                          />
                        </Box>
                        {/* Title */}
                        <Typography
                          variant="h5"
                          sx={{
                            color: isActive ? "#fff" : "#EC1B24",
                            fontWeight: 500,
                            size: "20px",
                            lineHeight: "30px",
                            marginTop: "10px",
                            fontFamily: "DM Sans, sans-serif",

                          }}
                        >
                          {card.title}
                        </Typography>

                        {/* Description */}
                        <Typography
                          variant="body2"
                          sx={{
                            height: "140px",
                            color: "#FFFFFF",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "22.4px",
                            textAlign: "center",
                            marginTop: "20px",
                            fontFamily: "DM Sans, sans-serif",
                          }}
                        >
                          {card.desc}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Slider>
            </Box>
          </Box>
        </Box>

        {/* Footer Section */}
        <Box
          sx={{
            width: "100%",
            height: "299px",
            backgroundColor: "#000000",
            marginTop: 15,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* Background Image */}
          <img
            src={FooterImg}
            alt="Footer"
            style={{
              width: "100%",
              height: "299px",
              objectFit: "cover",
            }}
          />

          {/* Text */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              justifyContent: "center",
              width: "663px",
              maxWidth: "663px",
              color: "white",
              textAlign: "center",
              position: "absolute",
              marginLeft: "20%",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                fontFamily: "DM Sans",
                fontWeight: "500",
                fontSize: "36px",
                lineHeight: "46px",
                color: "#FFFFFF",
                textAlign: "left",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "DM Sans, sans-serif",

              }}
            >
              Sign up for new product updates and program announcements.
            </Typography>

            {/* Email Input */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#FFFFFF",
                borderRadius: "100px",
                padding: "5px",
                height: "50px",
                width: "100%",
                maxWidth: "400px",
                marginTop: "20px",
              }}
            >
              <TextField
                id="custom-textfield"
                variant="standard"
                placeholder="Enter your email"
                fullWidth
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{
                          backgroundColor: "#EC1B24",
                          color: "#FFFFFF",
                          borderRadius: "50%",
                          width: "50px",
                          height: "50px",
                          "&:hover": {
                            backgroundColor: "#B7181E",
                          },
                        }}
                      >
                        <SendIcon
                          sx={{
                            transform: "rotate(-45deg)",
                            color: "transparent",
                            stroke: "#FFFFFF",
                            strokeWidth: "1.5",
                            width: "24px",
                            height: "24px",
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                  sx: {
                    backgroundColor: "transparent",
                    borderRadius: "100px",
                    padding: "10px",
                    fontSize: "16px",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HomeSwiper;
