import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import youtube from "../../Assets/HeaderIcon/Youtube.png";

import linkedIn from "../../Assets/HeaderIcon/linkedIn.png";
import Pdf from "../../Assets/Home/PdfIcon.png";
import { Link, useNavigate } from "react-router-dom";

import business from "../../Assets/HeaderIcon/business.png";
import norton from "../../Assets/HeaderIcon/Norton.png";
import strip from "../../Assets/HeaderIcon/strip.png";
import routes from "../../AppRoutes/routes.json";
const CommonFooter = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        marginTop: 5,
      }}
    >
      <Box
        sx={{ width: "100%", height: "11px", backgroundColor: "#EA1B24" }}
      ></Box>
      <Box
        sx={{
          width: "100%",
          height: "470px",
          backgroundColor: "#000000",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          paddingTop: 10,

          //   paddingLeft: 6,
        }}
      >
        <Box
          sx={{
            width: "90%",
            display: "flex",
            flexDirection: "row",
            gap: 2,
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "30%",
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                color: "#ffffff",
                fontFamily: "DM Sans, sans-serif",
              }}
            >
              SIGN UP FOR OUR NEWSLETTER
            </Typography>
            <TextField
              variant="standard"
              InputProps={{
                disableUnderline: false,
                // Ensures the underline style applies

                endAdornment: (
                  <InputAdornment position="end">
                    <Box sx={{ marginLeft: "15px" }}>
                      <Button
                        variant="text"
                        sx={{
                          color: "#EC1B24",
                        }}
                      >
                        SUBSCRIBE
                      </Button>
                    </Box>
                  </InputAdornment>
                ),
              }}
              placeholder="Enter email address"
              sx={{
                width: "90%",
                "& .MuiInput-underline:before": {
                  borderBottomColor: "#ffffff", // White border before focus
                  opacity: 0.8,
                },
                "& .MuiInput-underline:hover:before": {
                  borderBottomColor: "#ffffff", // White border on hover
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#ffffff", // White border after focus
                },
                input: {
                  color: "#ffffff", // White text inside the input
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "#ffffff", // White placeholder text
                  opacity: 0.8, // Ensures full opacity for the placeholder
                },
              }}
            />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: 2,
                //   justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box>
                {" "}
                <IconButton
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/@PWRSteer",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  <img src={youtube} alt="youtube" />
                </IconButton>
              </Box>
              <Box>
                {" "}
                <IconButton
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/showcase/pwr-steer/",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  <img src={linkedIn} alt="youtube" />
                </IconButton>
              </Box>
              {/* <Box>
                {" "}
                <IconButton onClick={()=>window.open(pdfUrl, "_blank", "noopener,noreferrer")}>
                  <img src={insta} alt="youtube" />
                </IconButton>
              </Box> */}
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: 1,
              }}
            >
              <img src={Pdf} />
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 400,
                    color: "#ffffff",
                    fontFamily: "DM Sans, sans-serif",
                  }}
                >
                  PWR Brochure
                </Typography>
                <a
                  href="/public/pdf/PWR_BrochureSinglePage_1124 2.pdf"
                  download="PWR_Brochure.pdf"
                  style={{
                    color: "#FF0000",
                    fontSize: "10px",
                    fontWeight: 400,
                    textDecoration:'underline'
                  }}
                >
                  Download Now
                </a>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "20%",
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography
              sx={{
                color: "#ffffff",
                fontWeight: 500,
                fontSize: "14px",
                fontFamily: "DM Sans, sans-serif",
              }}
            >
              INFORMATION
            </Typography>
            <Typography
              onClick={() => {
                navigate(routes.About);
              }}
              sx={{
                color: "#ffffff",
                fontWeight: 400,
                fontSize: "12px",
                fontFamily: "DM Sans, sans-serif",
                cursor: "pointer",
                "&:hover": { textDecoration: "underline" },
              }}
            >
              About
            </Typography>
            <Typography
              sx={{
                color: "#ffffff",
                fontWeight: 400,
                fontSize: "12px",
                fontFamily: "DM Sans, sans-serif",
              }}
            >
              Accessibility
            </Typography>

            <Typography
              sx={{
                color: "#ffffff",
                fontWeight: 400,
                fontSize: "12px",
                fontFamily: "DM Sans, sans-serif",
              }}
            >
              Contact
            </Typography>
            <Typography
              sx={{
                color: "#ffffff",
                fontWeight: 400,
                fontSize: "12px",
                fontFamily: "DM Sans, sans-serif",
              }}
            >
              Help
            </Typography>
            <Typography
              sx={{
                color: "#ffffff",
                fontWeight: 400,
                fontSize: "12px",
                fontFamily: "DM Sans, sans-serif",
              }}
            >
              Site Map
            </Typography>
          </Box>
          <Box
            sx={{
              width: "20%",
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography
              sx={{
                color: "#ffffff",
                fontWeight: 500,
                fontSize: "14px",
                fontFamily: "DM Sans, sans-serif",
              }}
            >
              LEGAL
            </Typography>

            <Typography
              sx={{
                color: "#ffffff",
                fontWeight: 400,
                fontSize: "12px",
                fontFamily: "DM Sans, sans-serif",
              }}
            >
              Privacy Policy
            </Typography>

            <Typography
              sx={{
                color: "#ffffff",
                fontWeight: 400,
                fontSize: "12px",
                fontFamily: "DM Sans, sans-serif",
              }}
            >
              Terms of Use{" "}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Divider
            orientation="horizontal"
            sx={{
              borderColor: "#ffffff", // Set the color explicitly for the divider
              // borderWidth: "0.5px",     // Optional: Define the thickness
              width: "85%",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "85%",
              marginTop: 10,
            }}
          >
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "12px",
                color: "#ffffff",
                fontFamily: "DM Sans, sans-serif",
              }}
            >
              Copyright © 2024 PWR STEER. All Rights Reserved.
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={business} />
              <img src={norton} />
              <img src={strip} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CommonFooter;
