import React from 'react'
import LayoutComponent from '../Layout/LayoutComponent'
import { Outlet } from 'react-router-dom'
import HomePage from '../Pages/Home/HomePage'

const AppIndex = () => {
  return (
   <HomePage>
    <Outlet/>
   </HomePage>
  )
}

export default AppIndex