import React from 'react'
import LayoutComponent from "../../Layout/LayoutComponent";
import CommonFooter from "../Footer/CommonFooter";
import{ Box, Typography } from "@mui/material";
import CommonVideos from '../CommonComponent/CommonVideos';

export const Videos = () => {
  return (
    <LayoutComponent>

<Box sx={{width: "100%", height: "900px", }}>
   <Box sx={{
    margin:'70px'
   }}>
    <Typography sx={{
        width:'204px',
        height:'42px',
        fontFamily:'DM Sans',
        fontWeight:800,
        fontSize:'60px',
        lineHeight:'27px',
        color:'#000000'
    }}>
    Videos
    </Typography>

    <hr style={{
        width:'100%',
        color:"#D9D9D9",
        marginTop:"20px"
    }} />

    <CommonVideos />
    </Box>
</Box>
        <CommonFooter />
    </LayoutComponent>
)
}
