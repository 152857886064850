import React, { useState, useEffect } from 'react';
import {useSelector } from "react-redux";
import { Box, Typography, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const headerCellStyles = {
  width: "100%",
  height: "41px",
  padding: "10px",
  textAlign: "center",
  fontWeight: 600,
  fontFamily: "DM Sans, sans-serif",
  fontSize: "16px",
  border: "0.5px solid #BDBDBD",
  lineHeight: "20px",
  backgroundColor: "#EAEAEA",
};

const cellStyles = {
  height: "30px",
  padding: "10px",
  textAlign: "center",
  fontFamily: "DM Sans, sans-serif",
  fontSize: "14px",
  borderRight: "1px solid #ccc",
};

export const Application = () => {
    const[filteredData, setFilteredData]=useState([]);
    const[searchItem, setSearchItem]=useState();
    const{application}=useSelector((state)=> state.CommonStore);

    useEffect(() => {
      setFilteredData(application); 
    }, [application]);
  
    const searchData = (value) => {
      if (value.trim() === "") {
        setFilteredData(application); 
      } else {
        const lowerCaseSearch = value.toLowerCase();
        const filtered = application.filter(
          (row) =>
            row.make.toLowerCase().includes(lowerCaseSearch) ||
            row.model.toLowerCase().includes(lowerCaseSearch) ||
            row.engine.toLowerCase().includes(lowerCaseSearch) ||
            row.year.toString().includes(lowerCaseSearch)
        );
        setFilteredData(filtered);
      }
    };
  

    const handleSearchChange = (e) => {
      const value = e.target.value;
      setSearchItem(value);
      searchData(value);
    };
  
    const handleSearchClick = () => {
      searchData(searchItem);
    };
  
    if (!application.length) {
      return null; 
    }
  
  return (
    <Box
      sx={{
        width: "100%",
        height:'auto',
        maxHeight: "730px", 
        border: "1px solid #ccc",
        borderRadius: "13px",
        backgroundColor: "#FAFAFA",
        display: "flex",
        flexDirection: "column", 
      }}
    >
      {/* Header Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
          padding: "20px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "DM Sans, sans-serif",
            fontWeight: 700,
            fontSize: "16px",
            color: "#55120B",
          }}
        >
          Application
        </Typography>

        <Box
          sx={{
            width: "359px",
            height: "30px",
            display: "flex",
            alignItems: "center",
            border: "1px solid #747474",
            backgroundColor: "#FFFFFF",
            borderRadius: "44px",
            padding: "5px 10px",
          }}
        >
          {/* Input Field */}
          <InputBase
            placeholder="Search by Make, Model, Engine"
            sx={{
              fontFamily: "DM Sans, sans-serif",
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "12px",
              width: "100%",
            }}
            value={searchItem}
            onChange={handleSearchChange}
          />

          {/* Search Icon */}
          <Box
            sx={{
              width: "32px",
              height: "32px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              backgroundColor: "#EC1B24",
              marginLeft: "8px",
              cursor: "pointer",
            }}
          >
            <SearchIcon
              sx={{
                color: "#FFFFFF",
                width: "16px",
                height: "16px",
              }}
              onClick={handleSearchClick}
            />
          </Box>
        </Box>
      </Box>

      {/* Table Section */}
      <Box
        sx={{
          // flex: 1, 
          width:'100%',
          height:'auto',
          maxHeight:'660px',
          marginBottom:'10px',
          overflowY: "auto",
          overflowX: "hidden",
          "&::-webkit-scrollbar": {
            width: "3px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#EC1B24",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#D3D3D3",
          },
        }}
      >
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            tableLayout: "fixed",
          }}
        >
          <thead>
            <tr>
              <th style={headerCellStyles}>Year</th>
              <th style={headerCellStyles}>Make</th>
              <th style={headerCellStyles}>Model</th>
              <th style={headerCellStyles}>Engine</th>
            </tr>
          </thead><tbody>
  {filteredData.length > 0 ? (
    filteredData.map((row, index) => (
      <tr
        key={index}
        style={{
          backgroundColor: index % 2 === 0 ? "#FAFAFA" : "#EAEAEA",
        }}
      >
        <td style={cellStyles}>{row.year}</td>
        <td style={cellStyles}>{row.make}</td>
        <td style={cellStyles}>{row.model}</td>
        <td style={cellStyles}>{row.engine}</td>
      </tr>
    ))
  ) : (
    <tr>
      <td
        colSpan={4} 
        style={{
          textAlign: "center",
          padding: "20px",
          fontFamily: "DM Sans, sans-serif",
          fontWeight: 600,
          fontSize: "16px",
          color: "#ccc",
        }}
      >
        No matching records found
      </td>
    </tr>
  )}
</tbody>

        </table>
      </Box>
    </Box>
  );
};
