import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography, 
  OutlinedInput,Chip 
  
} from "@mui/material";
import React, { useEffect, useState } from "react";
import LayoutComponent from "../../Layout/LayoutComponent";
import backgroundimg from "../../Assets/BackgroudImages/Ecatlogbackground.png";
import AutoComplete from "../CommonComponent/AutoComplete";
import Product1 from "../../Assets/ProductImages/Product1.png";
import Product2 from "../../Assets/ProductImages/Product2.png";
import Product3 from "../../Assets/ProductImages/Product3.png";
import Product4 from "../../Assets/ProductImages/Product4.png";
import Product5 from "../../Assets/ProductImages/Product5.png";
import Product6 from "../../Assets/ProductImages/Product6.png";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CommonFooter from "../Footer/CommonFooter";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PartNoModal } from "../Home/PartNoModal";
import { getProductList } from "../CommonComponent/AutocompleteAction";
import { getNewRealses, showYearList } from "./ClassicSearchAction";
import { CommonSetupActions } from "../../Slices/Commonslice";
import { getProductAppData } from "./ProductDisplay/GearBoxAction";
import { showMakeList, showModelList, showEngineList, showDriveTypes } from "./ClassicSearchAction";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Ecatlog = () => {
  const [selectedValues, setSelectedValues] = useState([]);

  //states to set the selected year, make, model
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMake, setSelectedMake] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedEngine, setSelectedEngine] = useState(null);
  const [submodelVal,setSubmodelVal]=useState()

  const {  state  } = useLocation();
  const dispatch = useDispatch();
 

  const {
    quallifierCollection,
    subModel,
    productList,
    selectedProduct,
    radioValue,
  } = useSelector((state) => state.CommonStore);

  const { classicYearList, classicMakeList, classicModelList, classicEngineList } = useSelector((state) => state.CommonStore);

  useEffect(() => {
    dispatch(showYearList());
  }, []);

  //function to disable the autoselect
  const isDisabled = (selectNo) => {
    if (selectNo === 1) return false;
    if (selectNo === 2) return !selectedYear;
    if (selectNo === 3) return !selectedMake;
    return !selectedModel;
  };

  //set selected year
  const handleYearChange = (e, value) => {
    console.log(value);
    setSelectedYear(value);
    dispatch(CommonSetupActions.setCurrentSelectedYear(value))
    dispatch(showMakeList());
  };

  //set selected year
  const handleMakeChange = (e, value) => {
    console.log(value);
    setSelectedMake(value);
    dispatch(CommonSetupActions.setCurrentSelectedMake(value))
    dispatch(showModelList());
  };
  //set selected year
  const handleModelChange = (e, value) => {
    console.log(value);
    setSelectedModel(value);
    dispatch(CommonSetupActions.setCurrentSelectedModel(value))
    dispatch(showEngineList())
  };
  //set selected year
  const handleEngineChange = (e, value) => {
    console.log(value);
    setSelectedEngine(value);
    dispatch(CommonSetupActions.setCurrentSelectedEngine(value))
    dispatch(showDriveTypes(navigate));
  };
  const [value, setValue] = useState();
  const[value2, setValue2]= useState();
  console.log("location", state);
  const handleClick = () => {
    setOpen(true);
  };

  const navigate = useNavigate();

  const link = "https://newdb.pwrsteer.com/api";

  // const products = [
  //   {
  //     image: Product1,
  //     title: "70-5032PXR - 2004 Honda Odyssey Steering Pump",
  //   },
  //   {
  //     image: Product2,
  //     title: "70-5032PXR - 2004 Honda Odyssey Steering Pump",
  //   },
  //   {
  //     image: Product3,
  //     title: "70-5032PXR - 2004 Honda Odyssey Steering Pump",
  //   },
  //   {
  //     image: Product4,
  //     title: "70-5032PXR - 2004 Honda Odyssey Steering Pump",
  //   },
  //   {
  //     image: Product5,
  //     title: "70-5032PXR - 2004 Honda Odyssey Steering Pump",
  //   },
  //   {
  //     image: Product6,
  //     title: "70-5032PXR - 2004 Honda Odyssey Steering Pump",
  //   },
  // ];

  const handlesubModelChange=(e)=>{
  setSubmodelVal(e.target.value)
  console.log('qualli',quallifierCollection)
  const a=quallifierCollection.values[0]

  let b= subModel.values.find((data,ind)=>data.id===e.target.value)

  let qualifierId=`${a.id},${e.target.value}`
  let quallifiername=`${a.values},${b.values}`
  dispatch(getProductList(link, state.baseVehicleID,
    state.engineID,qualifierId,quallifiername))
  }
  
 

  const handleChange = (e) => {
    console.log("event", e);
    let a = quallifierCollection.values.find(
      (data, ind) => data.id === e.target.value
    );
    setValue(e.target.value);
    const data=e.target.value;
    console.log('data: ', data);
    setValue2(data);
    // setSelectedValues(e.target.value);
    dispatch(
      getProductList(
        link,
        state.baseVehicleID,
        state.engineID,
        e.target.value,
        a.values
      )
    );
  };

  const handleOnChange = (e) => {
    dispatch(CommonSetupActions.setRadioValue(e.target.value));
    dispatch(CommonSetupActions.setSelectedProduct({}));
    dispatch(getNewRealses(e.target.value));
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  console.log("subModel", subModel);

  useEffect(() => {
    dispatch(showYearList());
  }, []);

  return (
    <LayoutComponent>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 15,
        }}
      >
        <Box sx={{ width: "100%", height: "450px", position: "relative" }}>
          <img
            src={backgroundimg}
            alt="backgroundimg"
            width="100%"
            height="100%"
          />
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              marginTop: -40,
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "60px",
                color: "#EC1B24",
                fontFamily: "DM Sans",
              }}
            >
              E-Catalog{" "}
            </Typography>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "60px",
                color: "#ffffff",
                fontFamily: "DM Sans",
              }}
            >
              New Power Steering Products
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "70%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
                position: "absolute",
                backgroundColor: "#ffffff",
                marginTop: -3,
                padding: 3,
                borderRadius: "10px",
                boxShadow: " 0px 12px 24px 0px #00000008",
              }}
            >
              <Typography
                sx={{ fontWeight: 600, fontSize: "24px", color: "#55120B" }}
              >
                New Releases
              </Typography>
              <FormControl>
                <RadioGroup
                  value={radioValue}
                  onChange={handleOnChange}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value={1}
                    control={
                      <Radio
                        sx={{
                          color: "#EC1B24",
                          "&.Mui-checked": {
                            color: "#EC1B24",
                          },
                        }}
                      />
                    }
                    label="Power Steering Pump"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        color: "#55120B",
                        fontWeight: 400,
                        fontSize: "20px",
                        fontFamily: "DM Sans",
                      },
                    }}
                  />
                  <FormControlLabel
                    value={2}
                    control={
                      <Radio
                        sx={{
                          color: "#EC1B24",
                          "&.Mui-checked": {
                            color: "#EC1B24",
                          },
                        }}
                      />
                    }
                    label="Power Steering Rack"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        color: "#55120B",
                        fontWeight: 400,
                        fontSize: "20px",
                        fontFamily: "DM Sans",
                      },
                    }}
                  />
                  <FormControlLabel
                    value={3}
                    control={
                      <Radio
                        sx={{
                          color: "#EC1B24",
                          "&.Mui-checked": {
                            color: "#EC1B24",
                          },
                        }}
                      />
                    }
                    label="Steering Gear Box"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        color: "#55120B",
                        fontWeight: 400,
                        fontSize: "20px",
                        fontFamily: "DM Sans",
                      },
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "85%",
              backgroundColor: "#000000",
              marGinTop: 20,
              padding: 2,
              // height: "180px",
              borderRadius: "10px",

              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            {/* <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
              }}
            > */}
            <Typography
              sx={{ color: "#FFFFFF", fontWeight: 600, fontSize: "24px" }}
            >
              PWR Steer Search
            </Typography>
            {/* <Box sx={{ width: "100%" }}> */}
            <AutoComplete />
            {/* </Box> */}
            {quallifierCollection &&
            quallifierCollection.hasOwnProperty("name") &&
            quallifierCollection.values.length > 1 ? (
              <Box>
                <FormControl sx={{ m: 1, minWidth: 570 }}>
                  <InputLabel
                    id="demo-select-small-label"
                    sx={{
                      color: "#000000",
                      "&.Mui-focused": {
                        display: "none", // Hide on focus
                      },
                    }}
                  >
                    Select {quallifierCollection.name[0]}
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={value}
                    label="Age"
                    onChange={handleChange}
                    sx={{
                      backgroundColor: "white",
                      "& .MuiInputLabel-root": {
                        color: "#000000 !important",
                      },
                    }}
                  >
                    {quallifierCollection.values.map((data, ind) => {
                      return (
                        <MenuItem
                          value={data.id}
                          name={data.values}
                          id={data.values}
                        >
                          {data.values}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            ) : (
              quallifierCollection &&
              quallifierCollection.hasOwnProperty("name") && (
                <Box>
                  <FormControl sx={{ m: 1, minWidth: 570 }}>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={quallifierCollection.values[0].id}
                      label="Age"
                      defaultValue={quallifierCollection.values[0].id}
                      disabled
                      sx={{
                        backgroundColor: "white",
                        "& .MuiInputLabel-root": {
                          color: "#000000 !important",
                        },
                        "& .MuiSelect-select": {
                          fontWeight: 700,
                          fontSize: "20px",
                        },
                      }}
                    >
                      <MenuItem value={quallifierCollection.values[0].id}>
                        {quallifierCollection.values[0].values}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              )
            )}

            {subModel && subModel.hasOwnProperty("name") && (
              <Box>
                <FormControl sx={{ m: 1, minWidth: 570 }}>
                  <InputLabel
                    id="demo-select-small-label"
                    sx={{
                      color: "#000000",
                      "&.Mui-focused": {
                        display: "none", // Hide on focus
                      },
                    }}
                  >
                    {subModel.name[0]}
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={submodelVal}
                    label="Age"
                    onChange={handlesubModelChange}
                    sx={{
                      backgroundColor: "white",
                      "& .MuiInputLabel-root": {
                        color: "#000000 !important",
                      },
                    }}
                  >
                    {subModel.values.map((data, ind) => {
                      return <MenuItem value={data.id}>{data.values}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Box>
            )}
            {/* </Box> */}
            <Box onClick={handleClick}>
              <Typography
                sx={{
                  color: "#EC1B24",
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontWeight: 700,
                  fontSize: "16px",
                  fontFamily: "DM Sans",
                }}
              >
                Part Numbering Highlight
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ width: "85%", display: "flex", flexDirection: "row", gap: 2 }}
          >
            <Box sx={{ width: "30%" }}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: 2,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "30px",
                    color: "#55120B",
                    fontFamily: "DM Sans",
                    textAlign: "left",
                  }}
                >
                  Still Can’t Find Your Product ?
                </Typography>
                <Typography
                  sx={{
                    width: "60%",
                    fontWeight: 600,
                    fontSize: "16px",
                    color: "#000000",
                    fontFamily: "DM Sans",
                  }}
                >
                  Tryout Our Classic Search Option
                </Typography>
                <Box
                  sx={{
                    width: "90%",
                    // height: "350px",
                    backgroundColor: "#000000",
                    borderRadius: "10px",
                    boxShadow: "0px 12px 24px 0px #0000001A",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    padding: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: "16px",
                      color: "#ffffff",
                      fontFamily: "DM Sans",
                    }}
                  >
                    Classic Search
                  </Typography>
                  <Autocomplete
                    sx={{ height: "50px" }}
                    options={classicYearList}
                    disabled={isDisabled(1)}
                    value={selectedYear || ""}
                    onChange={handleYearChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Year"
                        sx={{
                          backgroundColor: "#D9D9D9",
                          borderRadius: "5px",
                          "& .MuiFilledInput-root": {
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "#D9D9D9",
                            width: "100%",
                            height: "100%",
                            borderRadius: "50px",
                            paddingRight: "4px!important",

                            "& input": {
                              // Center align the input text
                              marginBottom: 2,
                            },
                          },
                          "& .MuiOutlinedInput-root": {
                            color: "#FFFFFF",
                            borderColor: "#FFFFFF",

                            // Class for the border around the input field
                            "& .MuiOutlinedInput-notchedOutline": {
                              color: "#FFFFFF",
                              borderColor: "#FFFFFF",
                            },
                          },
                          // Class for the label of the input field
                          "& .MuiInputLabel-outlined": {
                            color: "#000000",
                            fontWeight: 500,
                            fontSize: "14px",
                            alignItems: "center",
                            marginBottom: 2,
                          },
                        }}
                      />
                    )}
                  />
                  <Autocomplete
                    options={classicMakeList}
                    disabled={isDisabled(2)}
                    value={selectedMake || ""}
                    onChange={handleMakeChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Make"
                        sx={{
                          backgroundColor: "#D9D9D9",
                          borderRadius: "5px",
                          "& .MuiFilledInput-root": {
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "#D9D9D9",
                            width: "100%",
                            height: "100%",
                            borderRadius: "50px",
                            paddingRight: "4px!important",

                            "& input": {
                              // Center align the input text
                              marginBottom: 2,
                            },
                          },
                          "& .MuiOutlinedInput-root": {
                            color: "#FFFFFF",
                            borderColor: "#FFFFFF",

                            // Class for the border around the input field
                            "& .MuiOutlinedInput-notchedOutline": {
                              color: "#FFFFFF",
                              borderColor: "#FFFFFF",
                            },
                          },
                          // Class for the label of the input field
                          "& .MuiInputLabel-outlined": {
                            color: "#000000",
                            fontWeight: 500,
                            fontSize: "14px",
                            alignItems: "center",
                            marginBottom: 2,
                          },
                        }}
                      />
                    )}
                  />
                  <Autocomplete
                    options={classicModelList}
                    value={selectedModel || ""}
                    onChange={handleModelChange}
                    disabled={isDisabled(3)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Model"
                        sx={{
                          backgroundColor: "#D9D9D9",
                          borderRadius: "5px",
                          "& .MuiFilledInput-root": {
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "#D9D9D9",
                            width: "100%",
                            height: "100%",
                            borderRadius: "50px",
                            paddingRight: "4px!important",

                            "& input": {
                              // Center align the input text
                              marginBottom: 2,
                            },
                          },
                          "& .MuiOutlinedInput-root": {
                            color: "#FFFFFF",
                            borderColor: "#FFFFFF",

                            // Class for the border around the input field
                            "& .MuiOutlinedInput-notchedOutline": {
                              color: "#FFFFFF",
                              borderColor: "#FFFFFF",
                            },
                          },
                          // Class for the label of the input field
                          "& .MuiInputLabel-outlined": {
                            color: "#000000",
                            fontWeight: 500,
                            fontSize: "14px",
                            alignItems: "center",
                            marginBottom: 2,
                          },
                        }}
                      />
                    )}
                  />
                  <Autocomplete
                    options={classicEngineList}
                    value={selectedEngine || ""}
                    onChange={handleEngineChange}
                    disabled={isDisabled(4)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Engine"
                        sx={{
                          backgroundColor: "#D9D9D9",
                          borderRadius: "5px",
                          "& .MuiFilledInput-root": {
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "#D9D9D9",
                            width: "100%",
                            height: "100%",
                            borderRadius: "50px",
                            paddingRight: "4px!important",

                            "& input": {
                              // Center align the input text
                              marginBottom: 2,
                            },
                          },
                          "& .MuiOutlinedInput-root": {
                            color: "#FFFFFF",
                            borderColor: "#FFFFFF",

                            // Class for the border around the input field
                            "& .MuiOutlinedInput-notchedOutline": {
                              color: "#FFFFFF",
                              borderColor: "#FFFFFF",
                            },
                          },
                          // Class for the label of the input field
                          "& .MuiInputLabel-outlined": {
                            color: "#000000",
                            fontWeight: 500,
                            fontSize: "14px",
                            alignItems: "center",
                            marginBottom: 2,
                          },
                        }}
                      />
                    )}
                  />
                   {quallifierCollection &&
            quallifierCollection.hasOwnProperty("name") &&
            quallifierCollection.values.length > 1 ? (
              <Select
              value={value2}
              label='Select `${quallifierCollection.name[0]}`'
              onChange={handleChange}
                            input={<OutlinedInput />}
              sx={{
                backgroundColor: "#D9D9D9",
                borderRadius: "5px",
                "& .MuiFilledInput-root": {
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "#D9D9D9",
                  width: "100%",
                  height: "100%",
                  borderRadius: "50px",
                  paddingRight: "4px!important",
      
                  "& input": {
                    // Center align the input text
                    marginBottom: 2,
                  },
                },
                "& .MuiOutlinedInput-root": {
                  color: "#FFFFFF",
                  borderColor: "#FFFFFF",
      
                  // Class for the border around the input field
                  "& .MuiOutlinedInput-notchedOutline": {
                    color: "#FFFFFF",
                    borderColor: "#FFFFFF",
                  },
                },
                // Class for the label of the input field
                "& .MuiInputLabel-outlined": {
                  color: "#000000",
                  fontWeight: 500,
                  fontSize: "14px",
                  alignItems: "center",
                  marginBottom: 2,
                },
              }}
            >
             {quallifierCollection.values.map((data, ind) => {
                      return (
                        <MenuItem
                          value={data.id}
                          name={data.values}
                          id={data.values}
                        >
                          {data.values}
                        </MenuItem>
                      );
                    })}
            </Select>
            ): (quallifierCollection &&
              quallifierCollection.hasOwnProperty("name") && (
                <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={quallifierCollection.values[0].id}
                label="Age"
                defaultValue={quallifierCollection.values[0].id}
                disabled
                sx={{
                  backgroundColor: "#D9D9D9",
                  borderRadius: "5px",
                  "& .MuiFilledInput-root": {
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "#D9D9D9",
                    width: "100%",
                    height: "100%",
                    borderRadius: "50px",
                    paddingRight: "4px!important",
        
                    "& input": {
                      // Center align the input text
                      marginBottom: 2,
                    },
                  },
                  "& .MuiOutlinedInput-root": {
                    color: "#FFFFFF",
                    borderColor: "#FFFFFF",
        
                    // Class for the border around the input field
                    "& .MuiOutlinedInput-notchedOutline": {
                      color: "#FFFFFF",
                      borderColor: "#FFFFFF",
                    },
                  },
                  // Class for the label of the input field
                  "& .MuiInputLabel-outlined": {
                    color: "#000000",
                    fontWeight: 500,
                    fontSize: "14px",
                    alignItems: "center",
                    marginBottom: 2,
                  },
                }}
              >
                <MenuItem value={quallifierCollection.values[0].id}>
                  {quallifierCollection.values[0].values}
                </MenuItem>
              </Select>
            ))}
              {subModel && subModel.hasOwnProperty("name") && (
                <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={submodelVal}
                label="Age"
                onChange={handlesubModelChange}
                sx={{
                  backgroundColor: "#D9D9D9",
                  borderRadius: "5px",
                  "& .MuiFilledInput-root": {
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "#D9D9D9",
                    width: "100%",
                    height: "100%",
                    borderRadius: "50px",
                    paddingRight: "4px!important",
        
                    "& input": {
                      // Center align the input text
                      marginBottom: 2,
                    },
                  },
                  "& .MuiOutlinedInput-root": {
                    color: "#FFFFFF",
                    borderColor: "#FFFFFF",
        
                    // Class for the border around the input field
                    "& .MuiOutlinedInput-notchedOutline": {
                      color: "#FFFFFF",
                      borderColor: "#FFFFFF",
                    },
                  },
                  // Class for the label of the input field
                  "& .MuiInputLabel-outlined": {
                    color: "#000000",
                    fontWeight: 500,
                    fontSize: "14px",
                    alignItems: "center",
                    marginBottom: 2,
                  },
                }}
              >
                {subModel.values.map((data, ind) => {
                  return <MenuItem value={data.id}>{data.values}</MenuItem>;
                })}
              </Select>
              )}
                
                </Box>
              </Box>
            </Box>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                borderRightWidth: "0.7px",
                borderColor: "#808080",
              }}
            />
            <Box
              sx={{
                width: "100%",
                padding: 3,
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: 2,
              }}
            >
              {productList &&
                productList.length > 0 &&
                productList.map((product, index) => {
                  return (
                    <Box
                      sx={{
                        width: "31%",
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: "14px",
                        borderColor: "#808080",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        paddingBottom: 1,
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: "270px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#F9F9F9",
                          borderTopLeftRadius: "14px",
                          borderTopRightRadius: "14px",
                        }}
                      >
                        {(product.image || product.filename) && (
                          <img
                            src={
                              product.image ? product.image : product.filename
                            }
                            style={{
                              objectFit: "contain",
                              width: "90%",
                              height: "80%",
                            }}
                            alt="products"
                          />
                        )}
                      </Box>
                      <Box
                        sx={{
                          width: "90%",
                          height: "12%",
                          padding: 2,
                          display: "flex",
                          flexDirection: "column",
                          // gap: 1,
                          // justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: "16px",
                            color: "#252525",
                            fontFamily: "DM Sans",
                          }}
                        >
                          {product.partNumber} -{" "}
                          {product.ymm && product.ymm.replace(/-/g, " ")}{" "}
                          {selectedProduct?.label &&
                            selectedProduct.label.split(" ")[0] +
                              " " +
                              selectedProduct.label.split(" ")[1]}{" "}
                          {product.partTypename.replace(/-/g, " ")}
                          {/* `${
                              selectedProduct && selectedProduct.hasOwnProperty('label') &&
                              selectedProduct.label.split(" ")[0]
                            } ${selectedProduct.label.split(" ")[1]}` 
                           {product.partTypename}  */}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          onClick={() =>
                            dispatch(
                              getProductAppData(product.partNumber, navigate)
                            )
                          }
                          sx={{
                            width: "90%",
                            textTransform: "none",
                            backgroundColor: "#EC1B24",
                            height: "49px",
                            borderRadius: "44px",
                          }}
                          endIcon={<ArrowRightAltIcon />}
                        >
                          View Product
                        </Button>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </Box>
        <PartNoModal open={open} handleClose={handleClose} />
      </Box>
      <CommonFooter />
    </LayoutComponent>
  );
};

export default Ecatlog;
