import React from 'react'
import PDF from "../../Assets/Warranty/pdfdownload.png";
import { Link } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";

const EnglishWarrantyStat = () => {
  return (
      <Box>
                <Typography
                  sx={{
                    width: "100%",
                    height: "16px",
                    fontFamily: "DM Sans, sans-serif",
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "27px",
                    color: "#55120B",
                  }}
                >
                  Power Steering Warranty Statement
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    height: "806px",
                  }}
                >
                  <Box
                    sx={{
                      width: "823px",
                      height: "280px",
                      marginTop: "20px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "DM Sans, sans-serif",
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "27px",
                        color: "#000000",
                      }}
                    >
                      PWR Steer warrants its Power Steering Products to be free
                      from defects in materials or workmanship when used on
                      privately owned, non-commercial vehicles for as long as
                      such original purchaser owns the vehicle on which the part
                      was originally installed.
                      <br /> <br />
                      The warranty will begin upon the date of first
                      installation on the original vehicle. Subject to the
                      specific limitations as listed in this document, the
                      product, when installed in accordance with PWR Steer
                      installation instructions, will be exchanged if returned
                      to the seller who is authorized to represent this
                      warranty. Proof of purchase may be required. PWR Steer
                      approved instructions are packaged with the PWR Steer
                      product, available through authorized PWR Steer
                      distributors, or may be found at www.pwrsteer.com.
                      <br />
                      <br />
                      This warranty may exclude failures that are the direct
                      result of, but not limited to:
                    </Typography>
                  </Box>
                  <br />
                  <Box>
                    <ol
                      style={{
                        width: "823px",
                        fontFamily: "DM Sans, sans-serif",
                        fontSize: "14px",
                        lineHeight: "27px",
                        paddingLeft: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <li>
                        Alterations to the OEM suspension and steering system
                        design
                      </li>
                      <br />
                      <li>
                        Alterations to wheel and tire size or vehicle height
                        that exceed the OEM vehicle specifications • Use of non-
                        OE approved fluids
                      </li>
                      <br />
                      <li>
                        Internal damage from excessive system debris or extended
                        operation of the vehicle when fluid level is below the
                        OEM minimum capacity specification
                      </li>
                      <br />
                      <li>
                        Non-compliance with PWR Steer installation instructions
                      </li>
                      <br />
                      <li>
                        Exceeding the OEM specific gross vehicle weight or
                        towing limitations • Installation on a vehicle that is
                        not specified in the PWR Steer catalog application data
                      </li>
                      <br />
                      <li>
                        Fire, flood, explosions, collisions, external damage,
                        physical alterations, disassembly, or missing components
                      </li>
                      <br />
                      <li>
                        Use on Commercial Vehicles for more than 18 months from
                        installation. This warranty is in lieu of all other
                        warranties either expressed or implied, including the
                        implied warranty of merchantability and fitness for
                        purposes which are expressly excluded. PWR Steer shall
                        not be liable for consequential or incidental damages
                        resulting from or caused by the use, operation, failure,
                        or defect of any PWR Steer product. This warranty grants
                        you specific legal rights, and you may also have other
                        rights which vary from state to state. Oct
                      </li>
                    </ol>

                    <Box
                      sx={{
                        width: "255px",

                        height: "98px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        gap: 3,
                        marginTop: "70px",
                      }}
                    >
                      <Box>
                        <img src={PDF} />
                      </Box>
                      <Box
                        sx={{
                          width: "161px",
                          height: "74px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Box
                          sx={{
                            width: "191px",
                            height: "41px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "DM Sans, sans-serif",
                              fontWeight: 800,
                              fontSize: "16px",
                              lineHeight: "20px",
                              color: "#252525",
                              marginTop: "5px",
                            }}
                          >
                            Power Steering Warranty Statement
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            width: "105px",
                            height: "13px",
                            marginTop: "20px",
                          }}
                        >
                          <Link
                            style={{
                              fontFamily: "DM Sans, sans-serif",
                              color: "#EC1B24",
                              fontSize: "14px",
                              fontWeight: 600,
                            }}
                          >
                            Download Now
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
  )
}

export default EnglishWarrantyStat;
