import { Box, Button, Typography } from "@mui/material";
import React from "react";
import audi from "../../Assets/BackgroudImages/Audi.png";

import AutoComplete from "../CommonComponent/AutoComplete";

const PwrProductSearch = () => {
  
  return (
    <Box
      sx={{
        backgroundImage: `url(${audi})`,
        display: "flex",
        backgroundSize: "cover",
        backgroundPosition: "center",
        // justifyContent: "center",
        height: "800px",
        flexDirection: "column",
        marginTop: 0,
      }}
    >
      <Box
        sx={{
          width: "100%",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          marginTop: "70px",
        }}
      >
        <Typography
          sx={{
            color: "white",
            fontWeight: 700,
            fontSize: "75px",
            fontFamily: "DM Sans, sans-serif",
          }}
        >
          New{" "}
          <span style={{ color: "#EC1B24", fontFamily: "DM Sans, sans-serif" }}>
            Power Steering
          </span>
        </Typography>
        <Typography
          sx={{
            color: "white",
            fontWeight: 700,
            fontSize: "75px",
            fontFamily: "DM Sans, sans-serif",
          }}
        >
          Products
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          marginTop: "40px",
        }}
      >
        <AutoComplete />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "25px",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            textTransform: "none",
            borderColor: "white",
            color: "white",
            borderRadius: "50px",
            fontFamily: "DM Sans, sans-serif",
            fontWeight: 400,
            fontSize: "12px",
          }}
        >
          Power Steering Pump
        </Button>
        <Button
          variant="outlined"
          sx={{
            textTransform: "none",
            borderColor: "white",
            color: "white",
            borderRadius: "50px",
            fontFamily: "DM Sans, sans-serif",
            fontWeight: 400,
            fontSize: "12px",
          }}
        >
          Power Steering Rack
        </Button>
        <Button
          variant="outlined"
          sx={{
            textTransform: "none",
            borderColor: "white",
            color: "white",
            borderRadius: "50px",
            fontFamily: "DM Sans, sans-serif",
            fontWeight: 400,
            fontSize: "12px",
          }}
        >
          Steering Gear Box
        </Button>
      </Box>
    </Box>
  );
};

export default PwrProductSearch;
