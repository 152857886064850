import {
  Box,
  Typography,
  Button,
  TextField,
  Checkbox,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import React, { useState } from "react";
import Question1 from "../../Assets/Home/question1.png";
import GetInTouchImg from "../../Assets/Home/GetInTouch.png";
import Vector from "../../Assets/Home/VectorArrow.png";
import InputAdornment from "@mui/material/InputAdornment";
import USAFlag from "../../Assets/Home/usaFlag.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const FrequentlyAskedQuest = () => {
  const [question, setQuestion] = useState([
    {
      question:
        "What sets PWR Steer apart from other power steering part providers?",
      answer:
        "PWR Steer products are 100% new, engineered to meet or exceed Original Equipment (OE) quality standards, and are designed for long-lasting performance. Our parts come installation-ready with everything needed for a hassle-free fit, which helps save time and money, ensures the job is done right the first time. Additionally, our low warranty rates reflect our commitment to reliability and quality.",
    },
    {
      question: "Are PWR Steer products remanufactured?",
      answer:
        "No, PWR Steer specializes exclusively in brand-new power steering parts. Unlike remanufactured options, which may have a higher failure rate, our products are designed and built from scratch to meet stringent quality standards and deliver dependable performance every time.",
    },
    {
      question:
        "Does PWR Steer provide support for installation or technical questions?",
      answer:
        "Yes, PWR Steer offers dedicated technical support to assist with installation and any product-related questions. Our ASE-certified support team is available by phone and online to provide guidance and ensure a smooth installation process. Additionally, our YouTube channel, under the Steering Solutions section, offers instructional videos and tips.",
    },
    {
      question: "Do PWR Steer parts come with a warranty?",
      answer:
        "Yes, all PWR Steer products come with a limited lifetime warranty that reflects our confidence in their quality and durability. Our low warranty rates demonstrate our commitment to delivering reliable parts, so you can install with peace of mind knowing we stand behind our products.",
    },
    {
      question:
        "Why should I choose 100% new parts over remanufactured options?",
      answer:
        "Choosing 100% new parts, like those offered by PWR Steer, eliminates the risks associated with remanufactured products, which often have higher failure rates. Technicians prefer working with new parts because they provide a reliable, first-time fit and consistent performance, reducing installation time and minimizing the chance of returns. Our parts are designed to meet OE standards, delivering superior reliability and peace of mind that remanufactured parts simply can’t match.",
    },
    {
      question: "Have a questions?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
    },
  ]);

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "900px",
        boxSizing: "border-box",
        marginTop: "100px",
      }}
    >
      {/* freq. ask question title box */}
      <Box
        sx={{
          width: "100%",
          height: "25px",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontFamily: "DM Sans, sans-serif",
            // width:'482px',
            fontWeight: 500,
            fontSize: "36px",
            lineHeight: "46px",
            color: "#55120B",
          }}
        >
          Frequently Asked Questions
        </Typography>
      </Box>

      {/* Main box -- freq. ask quest and get in touch form  */}
      <Box
        sx={{
          width:'100%',
          marginTop: "60px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          boxSizing: "border-box",
          alignItems: "center",
          padding:'20px',
          gap: 2,
        }}
      >
        {/* questions */}
        <Box
          sx={{
            width: "50%",
            height: "668px",
          }}
        >
          {question.map((ques, index) => {
            return (
              <Accordion
                expanded={expanded === index}
                onChange={handleChange(index)}
                key={index}
                sx={{
                  marginBottom: "30px",
                  width: "90%",
                  height: "auto",
                  backgroundColor: "#FAFAFA",
                  padding: "5px",
                  borderRadius: "9px",
                  border: "1px solid #A6A6A6",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{
                    // width:'90%',
                    // width: "720px",
                    height: "11px",
                    color: "#000000",
                    fontFamily: "DM Sans, sans-serif",
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: "25px",
                    textAlign: "left",
                    // padding:'30px',
                  }}
                >
                  {ques.question}
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    width: "550px",
                    height: "auto",
                    fontFamily: "DM Sans, sans-serif",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "22px",
                    color: "#3A3A3A",
                    // paddingLeft:'30px',
                    textAlign:'left'

                  }}
                >
                  {ques.answer}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>

        {/* get in touch main box */}
        <Box
          sx={{
            // width: "50%",
            width: "650px",
            height: "685px",
            color: "#D9D9D9",
            borderRadius: "17px",
            backgroundImage: `url(${GetInTouchImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          {/* title */}
          <Box
            sx={{
              width: "600px",
              height: "68px",
              justifyContent: "flex-start",
              marginLeft: 0,
            }}
          >
            <Typography
              sx={{
                fontFamily: "DM Sans, sans-serif",
                fontWeight: 900,
                fontSize: "34px",
                lineHeight: "44px",
                color: "#FFFFFF",
              }}
            >
              Every{" "}
              <span
                style={{
                  color: "#EC1B24",
                }}
              >
                Project{" "}
              </span>
              <br />
              Starts with a
              <span
                style={{
                  color: "#EC1B24",
                }}
              >
                {" "}
                Plan !
              </span>
            </Typography>
          </Box>

          {/* form */}
          <form>
            <Box
              sx={{
                width: "560px",
                height: "474px",
                marginTop: "40px",
                border: "1px solid #A6A6A6",
                backgroundColor: "#FAFAFA",
                borderRadius: "13px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                paddingTop:'10px',
                padding: "20px",
                overflow: "hidden",
              }}
            >
              {/* Get in Touch */}
              <Typography
                sx={{
                  fontFamily: "DM Sans, sans-serif",
                  fontWeight: 700,
                  fontSize: "24px",
                  lineHeight: "15px",
                  color: "#000000",
                }}
              >
                Get in Touch
              </Typography>

              {/* Input Fields */}
              <Box
                sx={{
                  marginTop: "15px",
                  display: "flex",
                  flexDirection: "row",
                  gap: "30px",
                }}
              >
                <TextField
                  placeholder="First Name"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "7px",
                    },
                  }}
                />
                <TextField
                  placeholder="Last Name"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "7px",
                    },
                  }}
                />
              </Box>

              <Box
                sx={{
                  marginTop: "15px",
                  display: "flex",
                  flexDirection: "row",
                  gap: "30px",
                }}
              >
                <TextField
                  placeholder="Mobile"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <img
                            src={USAFlag}
                            alt="USA Flag"
                            style={{
                              width: "20px",
                              height: "12px",
                              borderRadius: "1px",
                            }}
                          />
                          <span
                            style={{
                              fontSize: "16px",
                              color: "#333333",
                              fontWeight: 400,
                              fontFamily: "DM Sans, sans-serif",
                            }}
                          >
                            +1
                          </span>
                          {/* Vertical Line */}
                          <Box
                            sx={{
                              width: "1px",
                              height: "54px",
                              backgroundColor: "#808080",
                              marginLeft: "8px",
                            }}
                          />
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "7px",
                    },
                  }}
                />
                <TextField
                  placeholder="Email"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "7px",
                    },
                  }}
                />
              </Box>

              <Box
                sx={{
                  marginTop: "15px",
                }}
              >
                <TextField
                  placeholder="Company"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "7px",
                    },
                  }}
                />
              </Box>

              <Box
                sx={{
                  marginTop: "15px",
                }}
              >
                <TextField
                  placeholder="Message"
                  multiline
                  rows={4}
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "7px",
                    },
                  }}
                />
              </Box>

              {/* Checkbox */}
              <Box
                sx={{
                  width: "565px",
                  height: "8px",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <Checkbox
                  defaultChecked
                  sx={{
                    color: "#348D2D",
                    "&.Mui-checked": {
                      color: "#348D2D",
                    },
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#7C7C7C",
                    lineHeight: "25px",
                    fontFamily: "DM Sans, sans-serif",
                  }}
                >
                  I confirm that I have read, understand, and agree to PWR
                  Steering.
                </Typography>
              </Box>

              {/* Submit Button */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    width: "126px",
                    height: "45px",
                    borderRadius: "100px",
                    backgroundColor: "#EC1B24",
                    color: "#FFFFFF",
                    textTransform: "none",
                    borderRadius: "50px",
                    padding: "10px 20px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  Submit
                  <img
                    src={Vector}
                    sx={{
                      width: "27.5px",
                      border: "1px solid #55120B",
                    }}
                  />
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};
