import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid2,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import routes from "../../AppRoutes/routes.json";
import { useDispatch, useSelector } from "react-redux";
import { searchProduct } from "./AutocompleteAction";
import { CommonSetupActions } from "../../Slices/Commonslice";

const AutoComplete = () => {
  const [searcheddata, setSearcheddata] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [debounceTimer, setDebounceTimer] = useState(null);
  const controllerRef = useRef(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [loading,setLoading]=useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedProduct = useSelector(
    (state) => state.CommonStore.selectedProduct
  );
  const link =
    "https://newdb.pwrsteer.com/api";

  const fetchProducts = (searchtextt) => {
    if (controllerRef.current) {
      controllerRef.current.abort();
      setSearcheddata([]);
    }

    controllerRef.current = new AbortController();

    if (!searchtextt.trim()) return;
    fetch(link + "/websitedynamic/" + searchtextt, {
      method: "GET",
      headers: {
        "Content-Type":  "application/json; charset=utf-8",
      
        
      },
    
      
      signal: controllerRef.current.signal,
    })
      .then((resp) => {
        console.log("checkApplciationInterchanges...status", resp);
        return resp.json();
      })
      .then((response) => {
        console.log("response", response);
        const formattedData = response.map((item) => ({
          label: item.label, // Use 'label' for display
          id: item.id, // Keep 'id' if needed later
          value: item.value, // Add other properties if necessary
          type: response[0].label,
        }));
        formattedData.splice(0, 1);
        console.log("formattedData", formattedData);
        setSearcheddata(formattedData);
      })
      .catch((err) => {
        console.error(err);
      }).finally(() => {
        controllerRef.current = null; 
      });
  };

  const handleInputChange = (event, newInputValue) => {
    // Clear the previous debounce timer
    // dispatch(CommonSetupActions.setSelectedProduct({}))
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    // Set new debounce timer
    const timer = setTimeout(() => {
      if (newInputValue.length >= 2) {
        fetchProducts(newInputValue);
      }
    }, 300); // Adjust debounce delay (300ms)

    setDebounceTimer(timer);
    setSearchText(newInputValue);
    if (newInputValue === "") {
      setSearcheddata([]);
      dispatch(CommonSetupActions.setQuallifierCollection({}))
      dispatch(CommonSetupActions.setSubModel({}))
    }
  };

  const handleOptionSelect = (event, newValue) => {
    console.log("Selected Value:", newValue);

    setSelectedValue(newValue);
    dispatch(CommonSetupActions.setSelectedProduct(newValue));
    // Update the selected value state
    setSearcheddata([])
  };
  console.log("");
  return (
    <Autocomplete
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      clearOnBlur={false}
      getOptionLabel={(option) => option.label || ""}
      value={selectedProduct || selectedValue || null}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      // open={searcheddata.length > 0}
      includeInputInList
      // filterSelectedOptions
      blurOnSelect={true}
     
      open={searcheddata && searcheddata.length>0}
      openOnFocus={false}
      onChange={handleOptionSelect}
      onInputChange={handleInputChange}
      options={searcheddata}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          sx={{
            backgroundColor: "white",
            width: "50%",
            height: "70px",
            borderRadius: "50px",
            justifyContent: "center",
            "& .MuiFilledInput-root": {
              display: "flex",
              justifyContent: "center",
              backgroundColor: "white",
              width: "100%",
              height: "100%",
              borderRadius: "50px",
              paddingRight: "4px!important",

              "& input": {
                // Center align the input text
                marginBottom: 2,
              },
            },
            "& .MuiOutlinedInput-root": {
              color: "#000",

              // Class for the border around the input field
              "& .MuiOutlinedInput-notchedOutline": {},
            },
            // Class for the label of the input field
            "& .MuiInputLabel-outlined": {
              color: "#2e2e2e",
              fontWeight: "bold",
              alignItems: "center",
              marginBottom: 2,
            },
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  marginBottom: 4,
                }}
              >
                <SearchIcon
                  style={{ color: "black" }}
                  sx={{ height: "35px", width: "35px" }}
                />
              </InputAdornment>
            ),
            endAdornment:
              ({ ...params.InputProps.endAdornment },
              (
                <InputAdornment position="end">
                  <Box
                    sx={{
                      marginTop: 0,
                      marginBottom: 2.3,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#EC1B24",
                        width: "120px",
                        height: "60px",
                        borderRadius: "100px",
                        padding: 1,
                        textTransform: "none",
                        fontFamily: "DM Sans, sans-serif",
                        fontWeight: 500,
                        fontSize: "16px",
                      }}
                      onClick={() =>
                        dispatch(searchProduct(selectedValue, navigate,setSearcheddata,setLoading))
                      }
                    >
                    {loading?<CircularProgress size='1rem' color="#ffffff"/> : "Search"}
                    </Button>
                  </Box>
                </InputAdornment>
              )),
          }}
        />
      )}
      //   renderOption={(props, option) => {
      //     console.log("option", option, props);
      //     return (
      //       <li>
      //         <Grid2 container alignItems="center">
      //           <Grid2
      //             item
      //             sx={{
      //               width: "calc(100% - 44px)",
      //               // wordWrap: "break-word",
      //               paddingLeft: 2,
      //             }}
      //           >
      //             <Box component="span" sx={{cursor:'pointer'}}>{option.label}</Box>
      //           </Grid2>
      //         </Grid2>
      //       </li>
      //     );
      //   }}
    />
  );
};

export default AutoComplete;
