import React from 'react'
import { Box, Button, Typography } from "@mui/material";
import PDF from "../../Assets/Warranty/pdfdownload.png";
import { Link } from "react-router-dom";

 const FrenchWarrantyStat = () => {
  return (
    <Box>
                <Typography
                  sx={{
                    width: "100%",
                    height: "16px",
                    fontFamily: "DM Sans, sans-serif",
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "27px",
                    color: "#55120B",
                  }}
                >
                  Déclaration de garantie de la direction assistée

                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    height: "806px",
                  }}
                >
                  <Box
                    sx={{
                      width: "823px",
                      height: "280px",
                      marginTop: "20px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "DM Sans, sans-serif",
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "27px",
                        color: "#000000",
                      }}
                    >
                     PWR Steer garantit que ses produits de direction assistée sont gratuits
                      contre les défauts de matériaux ou de fabrication lorsqu'il est utilisé sur
                      véhicules privés non commerciaux aussi longtemps que
                      cet acheteur d'origine est propriétaire du véhicule sur lequel la pièce
                      a été initialement installé.
                      <br /> <br />
                      La garantie débutera à la date du premier
                      installation sur le véhicule d'origine. Sous réserve du
                      limitations spécifiques énumérées dans ce document, le
                      produit, lorsqu'il est installé conformément à PWR Steer
                      instructions d'installation, sera échangé si retourné
                      au vendeur qui est autorisé à représenter ce
                      garantie. Une preuve d'achat peut être exigée. Direction PWR
                      les instructions approuvées sont fournies avec le PWR Steer
                      produit, disponible via PWR Steer agréé
                      distributeurs, ou peut être trouvé sur www.pwrsteer.com.
                      <br />
                      <br />
                      Cette garantie peut exclure les pannes qui sont directement liées
                      résultat, sans toutefois s'y limiter :
                    </Typography>
                  </Box>
                  <br />
                  <Box>
                    <ol
                      style={{
                        width: "823px",
                        fontFamily: "DM Sans, sans-serif",
                        fontSize: "14px",
                        lineHeight: "27px",
                        paddingLeft: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <li>
                      Modifications apportées à la suspension et au système de direction OEM
                      conception
                      </li>
                      <br />
                      <li>
                      Modifications de la taille des roues et des pneus ou de la hauteur du véhicule
                        qui dépassent les spécifications du véhicule OEM. • Utilisation de
                        Fluides approuvés par les constructeurs
                      </li>
                      <br />
                      <li>
                      Dommages internes dus à des débris excessifs du système ou à des
                        fonctionnement du véhicule lorsque le niveau de liquide est inférieur au
                        Spécification de capacité minimale OEM
                      </li>
                      <br />
                      <li>
                      Non-respect des instructions d'installation du PWR Steer
                      </li>
                      <br />
                      <li>
                      Dépassement du poids brut spécifique du véhicule ou
                        limitations de remorquage • Installation sur un véhicule qui est
                        non spécifié dans les données d'application du catalogue PWR Steer
                      </li>
                      <br />
                      <li>
                      Incendie, inondation, explosions, collisions, dommages extérieurs,
                      modifications physiques, démontage ou composants manquants
                      </li>
                      <br />
                      <li>
                      Utilisation sur des véhicules utilitaires pendant plus de 18 mois à partir de
                        installation. Cette garantie remplace toute autre
                        garanties expresses ou implicites, y compris la
                        garantie implicite de qualité marchande et d’adéquation à
                        à des fins expressément exclues. PWR Steer doit
                        ne pas être responsable des dommages consécutifs ou accessoires
                        résultant de ou causé par l'utilisation, le fonctionnement, la défaillance,
                        ou un défaut de tout produit PWR Steer. Cette garantie accorde
                        vous avez des droits légaux spécifiques, et vous pouvez également avoir d'autres
                        des droits qui varient d’un État à l’autre. Octobre
                      </li>
                    </ol>

                    <Box
                      sx={{
                        width: "255px",

                        height: "98px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        gap: 3,
                        marginTop: "70px",
                      }}
                    >
                      <Box>
                        <img src={PDF} />
                      </Box>
                      <Box
                        sx={{
                          width: "161px",
                          height: "74px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Box
                          sx={{
                            width: "191px",
                            height: "41px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "DM Sans, sans-serif",
                              fontWeight: 800,
                              fontSize: "16px",
                              lineHeight: "20px",
                              color: "#252525",
                              marginTop: "5px",
                            }}
                          >
                            Déclaration de garantie de la direction assistée
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            width: "175px",
                            height: "13px",
                            marginTop: "20px",
                          }}
                        >
                          <Link
                            style={{
                              fontFamily: "DM Sans, sans-serif",
                              color: "#EC1B24",
                              fontSize: "14px",
                              fontWeight: 600,
                            }}
                          >
                            Télécharger maintenant
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
  )
}

export default FrenchWarrantyStat;
