import React, {useState} from "react";
import LayoutComponent from "../../Layout/LayoutComponent";
import CommonFooter from "../Footer/CommonFooter";
import { Box, Typography } from "@mui/material";
import bgImg from "../../Assets/Rack&Pinions/rackBg.png";
import { useNavigate } from "react-router-dom";
import routes from "../../AppRoutes/routes.json";
import rackImg from "../../Assets/Rack&Pinions/rack1.png";
import gearIcon from "../../Assets/Rack&Pinions/gearIcon.png";
import pinion from "../../Assets/Rack&Pinions/pinion1.png";
import MaskGroup from "../../Assets/Rack&Pinions/Mask group.png";
import rack2 from "../../Assets/Rack&Pinions/rack2.png";
import Video from "../../Pages/CommonComponent/CommonVideos";

export const RackPinion = () => {
  
  const navigate = useNavigate();
  const RACK_PINION_FEATURES = [
    "Factory-Installed Integral Rack Mounting Bushings: Designed for a perfect fit and easy installation.",
    "Precision-Manufactured Internal Components: Engineered to meet OE specifications for exceptional reliability.",
    "PWR Specification Compliant Tie Rod, Boot, and Boot Seal Designs",
    "OE Specification-Compliant Tie Rod, Boot, and Seal Designs: Matches original equipment for guaranteed compatibility.",
    "Exact Routed and Coated Transfer Tubes: Provides corrosion resistance and long-lasting performance. Comprehensive Functional Testing: Each unit undergoes 100% functional testing to ensure it’s ready for installation.",
    "ISO/TS Certified Manufacturing: Manufactured through globally recognized, quality-assured processes.",
    "U.S.-Based Engineering Expertise: Innovative solutions developed at our Memphis, TN engineering facility.",
    "Real-World Proven Durability: Tested and validated in the field to ensure compatibility and reliability.",
  ];
  

  <Box component="ul">
    {RACK_PINION_FEATURES.map((feature, index) => (
      <li key={index} style={{ marginBottom: "10px" }}>
        {feature}
      </li>
    ))}
  </Box>;

  return (
    <LayoutComponent>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* 1st Row */}
        <Box sx={{ position: "relative", zIndex: 1 }}>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "800px",
              overflow: "hidden",
            }}
          >
            {/* Image */}
            <img
              src={bgImg}
              alt="Rack & Pinions"
              style={{
                objectFit: "cover",
                width: "100%",
                height: "100%",
                filter: "brightness(0.7)",
              }}
            />
            {/* Dark Overlay */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.3)", 
              }}
            ></Box>
          </Box>

          {/* Navigation Box */}
          <Box
            sx={{
              position: "absolute",
              top: "10%",
              left: "8%",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                display: "flex",
                alignItems: "center",
                gap: "12px",
                color: "#FFFFFF",
              }}
            >
              <Typography
                onClick={() => navigate(routes.Home)}
                sx={{
                  fontFamily: "DM Sans, sans-serif",
                  fontWeight: 400,
                  lineHeight: "27px",
                  textAlign: "center",
                  color: "#FFFFFF",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                Home
              </Typography>
              <span
                style={{
                  color: "#EC1B24",
                }}
              >
                &gt;
              </span>
             
              
              <Typography
                onClick={() => navigate(routes.GearBox)}
                sx={{
                  color: "#FFFFFF",
                  fontFamily: "DM Sans, sans-serif",
                  textDecoration: "none",
                  lineHeight: "27px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                Racks and Pinions
              </Typography>
            </Typography>
          </Box>
          {/* end of navigation */}

          {/* Floating Box */}
          <Box
            sx={{
              position: "absolute",
              top: "100%",
              left: "50%",
              transform: "translate(-50%, -90%)",
              width: "80%",

              // width: "1500px",
              height: "630px",
              backgroundColor: "white",
              borderRadius: "13px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              border: "0.7px solid #808080",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "50px",
              gap: 0,
              marginTop: "20px",
            }}
          >
            <Box
              sx={{
                width: "40%",
                height: "102px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 700,
                  fontSize: "51px",
                  lineHeight: "65.79px",
                  letterSpacing: "1%",
                  color: "#0E1415",
                }}
              >
                New Racks and Pinions
              </Typography>
              <Box>
                <hr
                  style={{
                    width: "auto",
                    color: "#E30000",
                    borderRadius: "5px",
                    border: "2px solid #E30000",
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                width: "70%",
                height: "640px",
              }}
            >
              <img src={rackImg} alt="Rack & Pinions" />
            </Box>
          </Box>
        </Box>

        {/* 2nd Row */}
        <Box
          sx={{
            position: "relative",
            // zIndex: 0,
            marginTop: "8%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            gap: 5,
            padding: "80px",
            // marginLeft: "1%",
            // marginRight: "4%",
          }}
        >
          {/* Left Box */}
          <Box
            sx={{
              // width: "50%",
              width: "624px",
              height: "600px",
              borderRadius: "19px",
              backgroundColor: "#F0F3F3",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "40px",
              paddingLeft: "40px",
              paddingRight: "40px",
            }}
          >
            <Box
              sx={{
                width: "506px",
                height: "523px",
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                padding: "20px",
                marginTop: "38px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <img
                  src={gearIcon}
                  alt="key points"
                  style={{
                    width: "32px",
                    height: "32px",
                  }}
                />
                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 700,
                    fontSize: "19px",
                    lineHeight: "27px",
                    color: "#000",
                  }}
                >
                  Key Features
                </Typography>
              </Box>

              <Box
                sx={{
                  width: "432px",
                  height: "461px",
                  color: "#252525",
                }}
              >
                <ul>
                  {RACK_PINION_FEATURES.map((feature, index) => (
                    <li
                      key={index}
                      style={{
                        width: "auto",
                        height: "auto",
                        color: "#252525",
                        marginBottom: "10px",
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "22px",
                        fontFamily: "DM Sans",
                      }}
                    >
                      {feature}
                    </li>
                  ))}
                </ul>
              </Box>
            </Box>
          </Box>

          {/* Right Box */}
          <Box
            sx={{
              width: "700px",
              // width: "50%",
              height: "350px",
              display: "flex",
              flexDirection: "column",
              gap: 7,
            }}
          >
            <Box
              sx={{
                width: "750px",
                height: "102px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 700,
                  fontSize: "50px",
                  lineHeight: "65px",
                  color: "#000000",
                }}
              >
                Performance and Reliability You Can Count On
              </Typography>
            </Box>
            <Box
              sx={{
                width: "525px",
                height: "110px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: "400px",
                  fontSize: "14px",
                  lineHeight: "25px",
                  color: "#252525",
                }}
              >
                At PWR Steer, we’re committed to delivering power steering
                products that exceed expectations. Engineered for precision and
                built for longevity, our 100% new Rack and Pinion systems
                eliminate the risks of remanufactured parts. Every unit is
                designed to optimize installation efficiency, reduce failures,
                and enhance customer satisfaction.
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* 3rd Row */}
        <Box
          sx={{
            width: "100%",
            height: "409px",
            backgroundColor: "#FAFAFA",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={pinion} alt="Pinion" style={{}} />
          </Box>
        </Box>

        {/* 4th row */}
        <Box
          sx={{
            width: "100%",
            height: "600px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            gap: "5",
            marginTop: "80px",
          }}
        >
          {/* left box */}
          <Box
            sx={{
              width: "591px",
              height: "547px",
            }}
          >
            <Typography
              sx={{
                width: "553px",
                height: "17px",
                color: "#000000",
                fontFamily: "DM Sans",
                fontWeight: 700,
                fontSize: "24px",
                lineHeight: "41px",
              }}
            >
              Why Choose PWR Steer Rack and Pinions?
            </Typography>

            <Typography
              sx={{
                width: "591px",
                height: "85px",
                fontFamily: "DM Sans",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "25px",
                color: "#0B0F11",
                marginTop: "40px",
              }}
            >
              PWR Steer is proud to lead the way in power steering innovation,
              combining decades of collective automotive expertise with
              cutting-edge technology. Every rack and pinion we produce is built
              from 100% new materials, ensuring OE-grade reliability and
              eliminating the challenges of remanufactured parts.
            </Typography>

            <Typography
              sx={{
                width: "516px",
                height: "11px",
                fontFamily: "DM Sans",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "22px",
                color: "#000000",
                marginTop: "40px",
              }}
            >
              What Sets Us Apart?
            </Typography>
            <ol>
              <li>
                <Typography
                  sx={{
                    width: "561px",
                    // height: "224px",
                    fontFamily: "DM Sans",
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "22px",
                    color: "#000000",
                  }}
                >
                  Decades of Collective Automotive Expertise
                  <br />
                  <span
                    style={{
                      fontFamily: "DM Sans",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "22px",
                      color: "#000000",
                    }}
                  >
                    Our engineering and manufacturing teams bring over 15 years
                    of combined experience across the automotive industry,
                    specializing in power steering systems, product development,
                    and quality assurance.
                  </span>
                </Typography>
              </li>
              <li>
                <Typography
                  sx={{
                    width: "561px",
                    marginTop: "10px",
                    fontFamily: "DM Sans",
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "22px",
                    color: "#000000",
                  }}
                >
                  Advanced Quality Standards
                  <br />
                  <span
                    style={{
                      fontFamily: "DM Sans",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "22px",
                      color: "#000000",
                    }}
                  >
                    ISO/TS 16949-compliant processes ensure that every product
                    meets or exceeds industry standards for durability,
                    performance, and safety.
                  </span>
                </Typography>
              </li>

              <li>
                <Typography
                  sx={{
                    width: "561px",
                    marginTop: "10px",
                    fontFamily: "DM Sans",
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "22px",
                    color: "#000000",
                  }}
                >
                  Design Innovation
                  <br />
                  <span
                    style={{
                      fontFamily: "DM Sans",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "22px",
                      color: "#000000",
                    }}
                  >
                    Each product is engineered to address common OE design
                    flaws, delivering improved durability and long-lasting
                    performance.
                  </span>
                </Typography>
              </li>
            </ol>
            <Typography
              sx={{
                width: "591px",
                height: "60px",
                fontFamily: "DM Sans",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "25px",
                color: "#000000",
              }}
            >
              By choosing PWR Steer Rack and Pinions, you’re investing in
              products that are rigorously tested to perform under the toughest
              conditions, ensuring smooth, reliable steering performance for
              years to come.
            </Typography>
          </Box>
          {/* right box */}
          <Box sx={{}}>
            <Box
              sx={{
                width: "570px",
                height: "484px",
                borderRadius: "19px",
                backgroundColor: "#F0F3F3",
                paddingTop: "40px",
                paddingLeft: "90px",
                paddingRight: "0px",
              }}
            >
              <Box
                sx={{
                  width: "471px",
                  height: "410px",
                  marginBottom: "0",
                  marginTop: "7%",
                }}
              >
                <img src={MaskGroup} />
              </Box>
            </Box>
          </Box>
        </Box>

        {/* 5th row */}
        <Box
          sx={{
            width: "100%",
            height: "600px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            gap: "5",
            marginTop: "80px",
          }}
        >
          {/* right box */}
          <Box sx={{}}>
            <Box
              sx={{
                width: "570px",
                height: "484px",
                borderRadius: "19px",
                backgroundColor: "#F0F3F3",
                padding: "20px",
                paddingBottom: "0",
              }}
            >
              <Box
                sx={{
                  width: "471px",
                  height: "410px",
                  marginBottom: "0",
                  marginTop: "7%",
                }}
              >
                <img src={rack2} />
              </Box>
            </Box>
          </Box>
          {/* left box */}
          <Box
            sx={{
              width: "591px",
              height: "547px",
            }}
          >
            <Typography
              sx={{
                width: "553px",
                height: "17px",
                color: "#000000",
                fontFamily: "DM Sans",
                fontWeight: 700,
                fontSize: "20px",
                lineHeight: "22px",
                marginBottom: "30px",
              }}
            >
              New Product Development Quality Control
            </Typography>

            <ol>
              <li>
                <Typography
                  style={{
                    fontFamily: "DM Sans",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "22px",
                    color: "#000000",
                    marginTop: "20px",
                  }}
                >
                  Built-In Quality from the Start: Every product is developed
                  using our Advanced Product Quality Planning (APQP) process,
                  ensuring a flawless launch.
                </Typography>
              </li>
              <li>
                <Typography
                  style={{
                    fontFamily: "DM Sans",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "22px",
                    color: "#000000",
                    marginTop: "20px",
                  }}
                >
                  Collaborative Engineering: Our teams work directly with
                  manufacturers to develop products that meet OE-level standards
                  for fit, form, and function.
                </Typography>
              </li>

              <li>
                <Typography
                  style={{
                    fontFamily: "DM Sans",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "22px",
                    color: "#000000",
                    marginTop: "20px",
                  }}
                >
                  Comprehensive Validation: Each component undergoes extensive
                  validation and testing for real-world performance and
                  reliability.
                </Typography>
              </li>
              <li>
                <Typography
                  style={{
                    fontFamily: "DM Sans",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "22px",
                    color: "#000000",
                    marginTop: "20px",
                  }}
                >
                  Customer-First Approach: Designed with technicians and
                  end-users in mind, PWR products combine ease of installation,
                  durability, and exceptional value, backed by industry-leading
                  warranties.
                </Typography>
              </li>
            </ol>
          </Box>
        </Box>

        {/* 6th row */}
        <Box
          sx={{
            width: "100%",
            padding: "0 80px",
            boxSizing: "border-box",
            marginTop: "60px",
          }}
        >
         <Video />
        </Box>
      </Box>
      <CommonFooter />
    </LayoutComponent>
  );
};