import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Typography,
  Autocomplete,
  TextField,
  CircularProgress,
  InputBase,
} from "@mui/material";
import LayoutComponent from "../../Layout/LayoutComponent";
import CommonFooter from "../Footer/CommonFooter";
import SearchIcon from "@mui/icons-material/Search";
import blog1 from "../../Assets/Articles/blog1.png";
import blog2 from "../../Assets/Articles/blog2.png";
import blog3 from "../../Assets/Articles/blog3.png";
import blog4 from "../../Assets/Articles/blog4.png";
import blog5 from "../../Assets/PwrSteer.png";
import blog6 from "../../Assets/Articles/blog2.png";
import profile from "../../Assets/Articles/author1.png";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import routes from "../../AppRoutes/routes.json";
import { CommonSetupActions } from "../../Slices/Commonslice";


const useStyles = {
  customScrollbar: {
    overflowY: "auto",
    height: "581px",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ec1b24",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#d1191f",
    },
    "&::-webkit-scrollbar-button": {
      display: "none",
      width: "0",
      height: "0",
    },
    // scrollbarColor: "#ec1b24 #f1f1f1",
    // scrollbarWidth: "thin",
  },
};

export const Articles = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const [blob, setBlob] = useState();
  const [searchItem,setSearchItem]=useState('')
  const [debouncedSearchItem, setDebouncedSearchItem] = useState(searchItem);
  const [activeBlog, setActiveBlog] = useState();
  const textRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const controllerRef = useRef(null);

  const link = "https://newdb.pwrsteer.com/api/";

  const slugify = (text) => {
    return text
      .toString()
      .toLowerCase()
      .trim()
      .replace(/[\s]+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, ""); // Remove all non-word chars
  };



  const fetchBlog = (searchitem) => {
    setBlob([])
    setActiveBlog()
  
    console.log('&&',searchitem)
    if (controllerRef.current) {
      controllerRef.current.abort();
      
    }

    controllerRef.current = new AbortController();
   
    const link2 =(searchitem||searchItem!=="")?`${link}articles/ ${searchitem}`:link + "articles"
    fetch(link2, {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      signal: controllerRef.current.signal,
    })
      .then((resp) => {
        return resp.json();
      })
      .then((response) => {
        console.log("responseblog", response);

        if(response.length===0){setSearchItem("")}
        setBlob(response);
        
        setActiveBlog(response[0]);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchBlog();
  }, []);

  const searchOptions = [
    { label: "option1", id: 1 },
    { label: "option2", id: 2 },
    { label: "option 3", id: 3 },
    { label: "option4", id: 4 },
    { label: "option5", id: 5 },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchItem(searchItem);
    }, 500); // Adjust the timeout value (500ms here) to your preference
  
    return () => clearTimeout(timer); // Clear the timeout on each change
  }, [searchItem]);
  
  useEffect(() => {
    if (debouncedSearchItem) {
      fetchBlog(debouncedSearchItem); // Call API after debounced search term is set
    }
  }, [debouncedSearchItem]);
  
  const handleSearchChange = (e) => {
    console.log("%%%",e.target.value)
    setSearchItem(e.target.value);
  };


  useEffect(()=>{
   if(searchItem==="" || searchItem===undefined){
    fetchBlog()
   }
  },[searchItem])

 
  useEffect(() => {
    if (textRef.current) {
      setIsTruncated(
        textRef.current.scrollHeight > textRef.current.clientHeight
      );
    }
  }, [activeBlog]);
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const parseHtml = (content) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content;
    const firstP = tempDiv.querySelector("p");
    return firstP.innerHTML;
  };
  const handleImageClick = (postTitle) => {
    dispatch(CommonSetupActions.setActiveArticle(activeBlog));
    // navigate(`/articles/${activeBlog.post_title}`)
    const slug = slugify(postTitle); // Convert the title to a slug
    navigate(`/articles/${slug}`); // Navigate with the slug
  };
  return (
    <LayoutComponent>
      
        <Box sx={{ position: "relative", width: "100%", height: "1100px" }}>
          <Box sx={{ margin: "20px 150px" }}>
            {/* blogs heading and search bar main div */}
            <Box
              sx={{
                height: "150px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {/* blogs heading */}
              <Box>
                <Typography
                  sx={{
                    fontFamily: "DM Sans, sans-serif",
                    fontWeight: 800,
                    fontSize: "60px",
                    color: "#000000",
                  }}
                >
                  Blogs
                </Typography>
              </Box>

              {/* Autocomplete search bar */}
              <Box
          sx={{
            width: "359px",
            height: "30px",
            display: "flex",
            alignItems: "center",
            border: "1px solid #747474",
            backgroundColor: "#FFFFFF",
            borderRadius: "44px",
            padding: "5px 10px",
          }}
        >
          {/* Input Field */}
          <InputBase
            placeholder="Search for anything"
            sx={{
              fontFamily: "DM Sans, sans-serif",
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "12px",
              width: "100%",
            }}
            value={searchItem}
            onChange={handleSearchChange}
          />

          {/* Search Icon */}
         
        </Box>
            </Box>
            <hr
              style={{
                marginTop: "-20px",
                border: "0.7px solid #D9D9D9",
              }}
            />
 
            {activeBlog ?<><Box
              sx={{
                width: "296px",
                height: "35px",
                marginTop: "25px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 700,
                  fontSize: "34px",
                  lineHeight: "47px",
                  color: "#0E1415",
                }}
              >
                Featured Articles
              </Typography>
              <hr
                style={{
                  width: "268px",
                  marginTop: "5px",
                  border: "3px solid #EC1B24",
                  borderRadius: "5px",
                  marginLeft: "0",
                  marginBottom: "10px",
                }}
              />
            </Box>

           
            <Box
              sx={{
                width: "100%",
                height: "700px",
                marginTop: "80px",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                // justifyContent:'center'
              }}
            >
              {/* blog title */}
              {activeBlog && (
                <Box
                  sx={{
                    width: "50%",
                    // height: "32px",
                    marginTop: "8px",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontFamily: "DM Sans, sans-serif",
                      fontWeight: 700,
                      fontSize: "30px",
                      lineHeight: "43px",
                      color: "#0E1415",
                    }}
                  >
                    {activeBlog.post_title}
                  </Typography>
                </Box>
              )}
              {/* selected blog details and other blogs */}
              <Box
                sx={{
                  width: "100%",
                  height: "650px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginTop: "20px",
                  padding: "2px",
                  gap: 10,
                }}
              >
                {/* active blog */}
                <Box
                  sx={{
                    width: "50%",
                    height: "630px",
                    display: "flex",
                    flexDirection: "column",
                    gap: 4,
                  }}
                >
                  {/* Author and Date */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {/* Author Name and Photo */}
                    {/* <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <img
                      src={activeBlog.profileImg}
                      alt="author"
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "5px",
                      }}
                    />
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "14px",
                          color: "#0B0F11",
                        }}
                      >
                        {activeBlog.authorName}
                      </Typography>
                      <Typography
                        sx={{
                          fontStyle: "italic",
                          fontSize: "14px",
                          color: "#ED1B24",
                        }}
                      >
                        {activeBlog.profile}
                      </Typography>
                    </Box>
                  </Box> */}

                    {/* Date */}
                    {activeBlog && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "DM Sans, sans-serif",
                            fontWeight: 700,
                            fontSize: "14px",
                            color: "#EC1B24",
                          }}
                        >
                          Article
                        </Typography>
                        <Box
                          sx={{
                            width: "2px",
                            height: "20px",
                            backgroundColor: "#0E1415",
                          }}
                        />
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                            color: "#0E1415",
                          }}
                        >
                          {activeBlog.new_date}
                        </Typography>
                      </Box>
                    )}
                  </Box>

                  {/* Blog Image and Date */}
                  {activeBlog && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        // justifyContent: "flex-end",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Box
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleImageClick(activeBlog.post_title)}
                      >
                        <img
                          src={activeBlog.post_image}
                          alt="blog"
                          style={{
                            width: "100%",
                            height: "auto",
                            maxHeight: "420px",
                            borderRadius: "12px",
                          }}
                        />
                      </Box>
                      <Typography
                        ref={textRef}
                        sx={{
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "#0B0F11",
                          marginTop: "20px",
                          textAlign: "left",
                          display: "-webkit-box",
                          WebkitLineClamp: isExpanded ? "unset" : 3,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          wordWrap: "break-word",
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: parseHtml(activeBlog.post_content),
                          }}
                        />
                        {/* {parseHtml(activeBlog.post_content)} */}
                      </Typography>
                      <Typography>
                        {isTruncated && !isExpanded && (
                          <span
                            style={{
                              fontWeight: 700,
                              fontSize: "16px",
                              color: "#EF3840",
                              cursor: "pointer",
                            }}
                            onClick={handleToggle}
                          >
                            Read More
                          </span>
                        )}
                        {isExpanded && (
                          <span
                            style={{
                              fontWeight: 700,
                              fontSize: "16px",
                              color: "#EF3840",
                              cursor: "pointer",
                            }}
                            onClick={handleToggle}
                          >
                            Show Less
                          </span>
                        )}
                      </Typography>
                    </Box>
                  )}
                </Box>

                {/* other blogs */}
                <Box
                  sx={{
                    ...useStyles.customScrollbar,
                    width: "50%",
                    height: "630px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    //   overflowY: "auto",
                  }}
                >
                  {blob &&
                    blob.length > 0 &&
                    blob.map((blog, index) => {
                      return (
                        <Box
                          key={index}
                          sx={{
                            width: "100%",
                            height: "auto",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: 4,
                            marginBottom: "40px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setActiveBlog(blog);
                            setIsExpanded(false);
                            setIsTruncated(false);
                          }}
                        >
                          {/* Image */}
                          <Box
                            sx={{
                              width: "231px",
                              height: "171px",
                              borderRadius: "10px",
                              overflow: "hidden",
                              flexShrink: 0,
                            }}
                          >
                            <img
                              src={blog.post_image}
                              alt="blog"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "10px",
                              }}
                            />
                          </Box>

                          {/* Title and Description */}
                          <Box
                            sx={{
                              width: "calc(100% - 140px)",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "flex-start",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 700,
                                fontSize: "20px",
                                fontFamily: "DM Sans, sans-serif",
                                lineHeight: "27px",
                                color: "#0E1415",
                                whiteSpace: "normal",
                                overflowWrap: "break-word",
                                wordBreak: "break-word",
                              }}
                            >
                              {blog.post_title}
                            </Typography>
                            <Typography
                              sx={{
                                fontFamily: "DM Sans, sans-serif",
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "22px",
                                color: "#0B0F11",
                                marginTop: "10px",
                                maxHeight: "44px",
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {parseHtml(blog.post_content)}
                            </Typography>

                            {/* Blog Metadata */}
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: 1.5,
                                marginTop: "10px",
                                width: "100%",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "DM Sans, sans-serif",
                                  fontWeight: 700,
                                  fontSize: "14px",
                                  color: "#EC1B24",
                                }}
                              >
                                Blogs
                              </Typography>
                              <Box
                                sx={{
                                  width: "2px",
                                  height: "20px",
                                  backgroundColor: "#0E1415",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontFamily: "DM Sans, sans-serif",
                                  fontWeight: 400,
                                  fontSize: "14px",
                                  color: "#0E1415",
                                }}
                              >
                                {blog.new_date}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                </Box>
              </Box>
            </Box></>:<Box
          sx={{
            width: "100%",
            height: "600px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <CircularProgress
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        </Box>}
          </Box>
        </Box>
       
        
      
      <CommonFooter />
    </LayoutComponent>
  );
};
