import routes from "../../AppRoutes/routes.json";
import { CommonSetupActions } from "../../Slices/Commonslice";
import { getProductAppData } from "../ProductPages/ProductDisplay/GearBoxAction";

export const searchProduct = (selectedProduct, navigate, setSearcheddata,setLoading) => {
  return (dispatch, getState) => {
    try {
      const product = getState().CommonStore.selectedProduct;
      console.log("hereeeeeee", product);
      console.log("****",product)
      const link =
        "https://newdb.pwrsteer.com/api";
      // const baseVehicleID=
      setLoading(true)
      if (selectedProduct.type === "Applications"||selectedProduct.type==="VIN") {
        let baseVehicleID =  selectedProduct.type==="VIN"?selectedProduct.value.split("!!")[0]:selectedProduct.value.split("===")[0];
        let engineID = selectedProduct.id.split("!")[3];
        console.log("split", baseVehicleID);
        fetch(
          `${link}/qualifierCollection/${baseVehicleID}/${engineID}/noqualifier/0/0`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
          }
        )
          .then((response) => {
            console.log("response1", response);
            return response.json();
          })
          .then((res) => {
            console.log("res", res);
            setLoading(false)

            if (res === "fail") {
              navigate(routes.Ecatlog);
              dispatch(getProductList(link, baseVehicleID, engineID ,"noqualifier","noqualifier"));
            } else {
              if (res.values.length === 1) {
                fetch(
                  `${link}/qualifierCollection/${baseVehicleID}/${engineID}/${res.values[0].id}/0/0`,
                  {
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json; charset=utf-8",
                    },
                  }
                ).then((response12) => {
                  console.log("response1", response12);
                  return response12.json();
               
                })
                .then((res1) => {
                 console.log('resss1',res1)
                 dispatch(CommonSetupActions.setSubModel(res1))
                })
              }

              dispatch(CommonSetupActions.setQuallifierCollection(res));
              navigate(routes.Ecatlog,{state:{baseVehicleID:baseVehicleID,engineID:engineID}});
            }

            // setSearcheddata([]);
            
          });
      }
      else{
        dispatch(getProductAppData(parseInt(selectedProduct.label),navigate))
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getProductList = (link, baseVehicleID, engineID,qualifier_id,qualifier_name) => {
  return (dispatch, getState) => {
    try {
      fetch(
        `${link}/websiteProductList/${baseVehicleID}/${engineID}/${qualifier_id}/${qualifier_name}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      )
        .then((response) => {
          console.log("response1", response);
          return response.json();
        })
        .then((res) => {
          console.log("res2", res);
          dispatch(CommonSetupActions.setProductList(res.partTypeArray))
        })
        .catch((err) => console.log(err));
    } catch (err) {}
  };
};
