import React from 'react';
import {useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";

export const CompetitorInterchange = () => {
  const{interchanges}= useSelector((state)=> state.CommonStore);

  if (!interchanges.length) {
    return null; 
  }

  
  return (
    <Box
      sx={{
        // flex: "1 1 50%",
        width:'50%'
      }}
    >
      <Box
        sx={{
          borderRadius: "13px",
          border: "1px solid #ccc",
          backgroundColor: "#EAEAEA",
          height:'auto',
          maxHeight: "570px",
        }}
      >
        {/* Header Section */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginBottom: "10px",
            marginLeft: "2%",
          }}
        >
          <Typography
            sx={{
              fontFamily: "DM Sans, sans-serif",
              fontWeight: 700,
              fontSize: "16px",
              color: "#55120B",
              paddingTop: "20px",
            }}
          >
            Competitor Interchange
          </Typography>
        </Box>

        {/* Table Section */}
        <Box
          sx={{
            width: "100%",
            marginTop: "20px",
            height:'auto',
            maxHeight: "450px",
            overflowY: "auto",
            overflowX: "hidden",
            marginBottom:'10px',
            // marginBottomLeft:'5px',
            "&::-webkit-scrollbar": {
              width: "3px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#EC1B24",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#D3D3D3",
            },
          }}
        >
          
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              tableLayout: "fixed",
            }}
          >
            <thead>
              <tr style={{ position: "sticky",
              height:'50px',
                    top: 0,
                    backgroundColor: "#FAFAFA",
                    textAlign: "center",
                    padding: "10px",
                    fontFamily: "DM Sans, sans-serif",
                    fontWeight: 600,
                    fontSize: "15px",
                    color: "#EC1B24",
                    borderBottom: "1px solid #ccc",
 }}>
                <th
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    fontFamily: "DM Sans, sans-serif",
                    fontWeight: 600,
                    fontSize: "15px",
                    color: "#EC1B24",
                    backgroundColor: "#FAFAFA",
                    borderRight: "1px solid #ccc",
                  }}
                >
                  Competitors Name
                </th>
                <th
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    fontFamily: "DM Sans, sans-serif",
                    fontWeight: 600,
                    fontSize: "15px",
                    color: "#EC1B24",
                    backgroundColor: "#FAFAFA",
                  }}
                >
                  Cross Reference
                </th>
              </tr>
            </thead>
            <tbody>
              {interchanges.map((intc, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#EAEAEA" : "#FFFFFF",
                    height: "50px",
                    color: "#252525",
                    fontSize: "14px",
                    fontWeight: 600,
                    fontFamily: "DM Sans, sans-serif",
                  }}
                >
                  <td
                    style={{
                      textAlign: "left",
                      borderRight: "1px solid #ccc",
                      padding:'20px'
                    }}
                  >
                    {intc.interchange_name}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {intc.partIntPartNumber}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </Box>
    </Box>
  );
};
