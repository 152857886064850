import { Box, Typography } from "@mui/material";
import React from "react";
import PwrSteer from "../../Assets/PwrSteer.png";

const ReliabilityAndQuality = () => {
  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          height: "497px",
          background:
            "radial-gradient(344.26% 169.01% at 50% 50.17%, #C61119 0%, #860F14 99.65%)",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: 7,
          }}
        >
          <Typography
            sx={{ fontWeight: 700, fontSize: "50px", color: "#FFFFFF", fontFamily: "DM Sans, sans-serif", }}
          >
            Built for Reliability, Backed by Quality
          </Typography>
        </Box>
        <Box sx={{ width: "58%",marginTop:2}}>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "14px",
              color: "#FFFFFF",
              textAlign: "justify",
              fontFamily: "DM Sans, sans-serif",
            }}
          >
            At PWR Steer, we pride ourselves on producing 100% new,
            installation-ready power steering systems designed to withstand the
            demands of today’s vehicles. Our warranty rates are consistently
            lower than the industry average, ensuring you receive a product
            built for longevity and reliability*. Unlike many aftermarket
            competitors, whose products often fail within the first 30 days, PWR
            Steer parts are crafted to exceed expectations right out of the box.
            Our commitment to quality and durability sets us apart in the
            industry, delivering peace of mind to every customer. When your
            reputation is on the line, choose PWR Steer.
          </Typography>

        </Box>
        
        <Box sx={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',marginTop:2}}>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "12px",
                color: "#FFFFFF",
                fontFamily: "DM Sans, sans-serif",
              
                
              }}
            >
              *Based on internal data, indicating a significantly lower failure
              rate compared to industry standards.*
            </Typography>
          </Box>
      </Box>
      <Box sx={{ width: "100%", height: "497px" }}>
        <Box
          sx={{
            position: "absolute",
            marginTop: "-180px",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <img
            src={PwrSteer}
            alt="steer"
            style={{ width: "1277px", height: "500px" }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ReliabilityAndQuality;
