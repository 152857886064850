import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "47%",
  borderRadius: "29px",
  bgcolor: "background.paper",
  boxShadow: 24,
  paddingTop:'16px',
//   overflowY: 'auto',
//   overflow:'hidden'
};

// const style = {
//     position: "fixed",
//     top: "0", 
//     left: "0",
//     width: "50%", 
//     height: "100%",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "flex-start", 
//     overflowY: "auto",
//     bgcolor: "rgba(0, 0, 0, 0.5)", 
//   };
  
// const contentStyle = {
//     marginTop: "20px", 
//     width: "50%", 
//     background: "#fff", 
//     borderRadius: "10px",
//     padding: "20px",
//     boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
//   };
  
export const PartNoModal = ({open,handleClose}) => {
  // const [open, setOpen] = React.useState(false);

  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const parts = [
    {
      partNo: "60-5023",
      partNOSpan: "P",
      desc: "Pump Supplied With ",
      descSpan: "Pulley",
    },
    {
      partNo: "60-5009",
      partNOSpan: "R",
      desc: "Pump Supplied With ",
      descSpan: "Reservoir",
    },
    {
      partNo: "60-6900",
      partNOSpan: "PR",
      desc: "Pump Supplied With ",
      descSpan: "Pulley & Reservoir + Corp",
    },
    {
      partNo: "70-5017",
      partNOSpan: "PXR",
      desc: "Pump Supplied With ",
      descSpan: "Pulley & Remote Reservoir + Cap",
    },
    {
      partNo: "61-5041",
      partNOSpan: "",
      desc: "Gear Box ",
      descSpan: "",
    },
    {
      partNo: "42-2760",
      partNOSpan: "",
      desc: "Rack & Pinions Hydraulic ",
      descSpan: "",
    },
    {
      partNo: "42-2993",
      partNOSpan: "T",
      desc: "Rack & Pinions Hydraulic supplied with ",
      descSpan: "Outer Tie Rods",
    },
    {
      partNo: "45-1252",
      partNOSpan: "",
      desc: "Rack & Pinions, Manual",
      descSpan: "",
    },
    {
      partNo: "45-4013",
      partNOSpan: "T",
      desc: "Rack & Pinions Manual Supplied with ",
      descSpan: "Outer Tie Rods",
    },
    {
      partNo: "47-8001",
      partNOSpan: "T",
      desc: "Rack & Pinions EPS All Supplied with ",
      descSpan: "Outer Tie Rods",
    },
  ];
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // hideBackdrop={true}
      >
        <Box sx={style}>
        {/* <Box sx={contentStyle}> */}

          {/* Header Section */}
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
              marginBottom: "40px",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{
                fontFamily: "DM Sans, sans-serif",
                fontSize: "28px",
                fontWeight: 900,
                textAlign: "center",
              }}
            >
              Part Numbering Highlight
            </Typography>
            <hr
              style={{
                border: "2px solid #EC1B24",
                width: "350px",
                borderRadius: "2px",
              }}
            />
          </Box>
          {parts.map((part, index) => {
            return (
              <React.Fragment key={index}>
                {/* Black Button Section */}
                <Box
                  sx={{
                    width: "100%",
                    height: "45px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    textAlign: "center",
                    gap: 3,
                    marginTop: "-2px",
                    paddingLeft: "40px",
                    // paddingRight:'40px'
                  }}
                >
                  <Box
                    sx={{
                      width: "160px",
                      height: "50px",
                      backgroundColor: "#000000",
                      color: "#FFFFFF",
                      borderTopLeftRadius: "9px",
                      borderTopRightRadius: "9px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "17px",
                        fontWeight: 500,
                      }}
                    >
                      {part.partNo}{" "}
                      <span style={{ color: "#EC1B24" }}>
                        {part.partNOSpan}
                      </span>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontFamily: "DM Sans, sans-serif",
                        fontWeight: 200,
                        padding: "10px",
                        color: "#3d3f45",
                      }}
                    >
                      {part.desc}
                      <span
                        style={{
                          color: "#EC1B24",
                          textDecoration: "underline",
                        }}
                      >
                        {part.descSpan}
                      </span>
                    </Typography>
                  </Box>
                </Box>

                {/* Bottom Gray Line */}
                <hr
                  style={{
                    border: "1px solid #ccc",
                    width: "99.5%",
                    marginTop: "-1px",
                    marginBottom:'20px'
                  }}
                />
              </React.Fragment>
            );
          })}
          </Box>
        {/* </Box> */}
      </Modal>
    </div>
  );
};
